import NotificationsIcon from '@mui/icons-material/Notifications';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ChipTypeMap } from '@mui/material/Chip/Chip';
import { AxiosResponse } from 'axios';
import { ReactNode } from 'react';

import { workerIssueStatusMock } from './issuesViews';

import { EChatTypes, EChatUserTypes } from '../enums/chats.enum';
import {
    EAutoAssignmentErrors,
    EAutoAssignmentStatus,
    ECategoryPreset,
    EDiagnosticStatus,
    EIssueCreatedSource,
    EIssueHelpType,
    EIssueStatus,
    EIssueTableHeadFilter,
    EIssueTriggers,
    EObserversFilter,
    EPaymentMethod,
} from '../enums/issues.enum';
import { IFilter } from '../interfaces/filter';
import { IChatTypeIssue, IIssue, IIssuesTableHead, IStatusFilter } from '../interfaces/issues';

export const issueHelpTypeNamesMock = {
    [EIssueHelpType.Consultation]: 'Консультация',
    [EIssueHelpType.Repair]: 'Ремонт',
    [EIssueHelpType.Installation]: 'Установка',
    [EIssueHelpType.Video]: 'Видео',
};
export const issueCreatedSourceMock = {
    [EIssueCreatedSource.Widget]: 'Виджет',
    [EIssueCreatedSource.SellerForm]: 'Форма продавца',
    [EIssueCreatedSource.ExternalForm]: 'Внешняя форма',
    [EIssueCreatedSource.CallCenter]: 'Колл-центр',
    [EIssueCreatedSource.Backoffice]: 'Сайт',
    [EIssueCreatedSource.App]: 'Приложение',
    [EIssueCreatedSource.Marketplace]: 'Маркетплейс',
    [EIssueCreatedSource.Classified]: 'Классифайд',
    [EIssueCreatedSource.Sws]: 'СОО',
    [EIssueCreatedSource.ChatAvito]: 'Чат Авито',
    [EIssueCreatedSource.TelegramBot]: 'Телеграм Бот',
} as { [key in EIssueCreatedSource]: string };

export const diagnosticStatusMock = {
    [EDiagnosticStatus.InProgress]: 'В процессе',
    [EDiagnosticStatus.Finished]: 'Завершена',
};

function isCanPutActiveStatusDefault(issue: IIssue, newStatus: EIssueStatus) {
    return !(newStatus === EIssueStatus.ActNRP && !issue.is_warranty);
}

export const getMockHelpTypes = (params: any): Promise<AxiosResponse<any, any>> => {
    const data = [
        {
            id: EIssueHelpType.Installation,
            name: 'Установка',
        },
        {
            id: EIssueHelpType.Repair,
            name: 'Ремонт',
        },
        {
            id: EIssueHelpType.Consultation,
            name: 'Консультация',
        },
        {
            id: EIssueHelpType.Video,
            name: 'Видео',
        },
    ];

    let filteredData = data;

    if (params.pk__in) {
        filteredData = filteredData.filter((item) => params.pk__in.includes(item.id));
    }

    if (params.exclude_pk__in) {
        filteredData = filteredData.filter((item) => !params.exclude_pk__in.includes(item.id));
    }

    const fakeResponse: AxiosResponse<any, any> = {
        data: filteredData,
        status: 200,
        statusText: 'OK',
        headers: {},
        // @ts-ignore
        config: {},
    };

    return new Promise((resolve) => {
        resolve(fakeResponse);
    });
};

/* Внимание!!! Сообщить бэку о необходимости уведомлять фронт,
 * в случае изменения массивов триггеров(пока не используются),
 * а также доступных для патча статусов заявки.
 *
 * issueStatusesMock самый главный объект поведения заявки
 * activePutStatuses, archivePutStatuses отвечают за доступность переходов заявки по статусам (смотри api putIssueStatus() и ему подобные)
 * triggers отвечают за доступность триггеров заявки, тригеры должны использоваться с методом putIssueStatus() и ему подобными */
export const issueStatusesMock: TIssueStatusLocal = {
    [EIssueStatus.New]: {
        title: 'Новая',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: workerIssueStatusMock.notAssign,
        triggers: [
            EIssueTriggers.ChangeAppointmentDatetime,
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.Assignee,
            EIssueTriggers.HideAssignee,
            EIssueTriggers.ShowAssignee,
            EIssueTriggers.Processed,
            EIssueTriggers.UpdateAddress,
            EIssueTriggers.SavePrice,
            EIssueTriggers.WaitingCall,
        ],
        activePutStatuses: [EIssueStatus.Processed, EIssueStatus.WaitingCall, EIssueStatus.Canceled],
        archivePutStatuses: [EIssueStatus.Processed],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.Processed]: {
        title: 'Обработана',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: null,
        triggers: [
            EIssueTriggers.ChangeAppointmentDatetime,
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.Assignee,
            EIssueTriggers.HideAssignee,
            EIssueTriggers.ShowAssignee,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.UpdateAddress,
            EIssueTriggers.SavePrice,
            EIssueTriggers.WaitingCall,
        ],
        activePutStatuses: [
            EIssueStatus.PartsSearch,
            EIssueStatus.MasterSearch,
            EIssueStatus.WaitingCall,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: [EIssueStatus.MasterSearch],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.MasterNotFound]: {
        title: 'Мастер не найден',
        color: 'error',
        colorHex: '#F0777766',
        colorText: '#D32F2F',
        workerIssueStatus: workerIssueStatusMock.notAssign,
        triggers: [EIssueTriggers.CancelAdmin, EIssueTriggers.MasterSearch, EIssueTriggers.SavePrice],
        activePutStatuses: [EIssueStatus.Processed, EIssueStatus.MasterSearch, EIssueStatus.Canceled],
        archivePutStatuses: [EIssueStatus.MasterSearch],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.MasterSearch]: {
        title: 'Поиск мастера',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: workerIssueStatusMock.search,
        triggers: [
            EIssueTriggers.ChangeAppointmentDatetime,
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.Accept,
            EIssueTriggers.Assignee,
            EIssueTriggers.HideAssignee,
            EIssueTriggers.ShowAssignee,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.UpdateAddress,
            EIssueTriggers.SavePrice,
            EIssueTriggers.WaitingCall,
        ],
        activePutStatuses: [EIssueStatus.PartsSearch, EIssueStatus.WaitingCall, EIssueStatus.Canceled],
        archivePutStatuses: [EIssueStatus.MasterNotFound],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.PaymentRequired]: {
        title: 'Ожидает оплаты',
        color: 'warning',
        colorHex: '#EFAD4B66',
        colorText: '#EF6C00',
        workerIssueStatus: workerIssueStatusMock.assign,
        triggers: [
            EIssueTriggers.SetPrice,
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.InProgress,
            EIssueTriggers.Solved,
        ],
        activePutStatuses: [
            EIssueStatus.WaitingForReceipt,
            EIssueStatus.WaitingForCash,
            EIssueStatus.Solved,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: [EIssueStatus.WaitingForReceipt, EIssueStatus.Solved],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.WaitingForReceipt]: {
        title: 'Ожидание счёта',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: workerIssueStatusMock.assign,
        triggers: [
            EIssueTriggers.SetPrice,
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.InProgress,
            EIssueTriggers.SavePrice,
            EIssueTriggers.Solved,
        ],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.WaitingForCash,
            EIssueStatus.PaymentRequired,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: [EIssueStatus.InProgress],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.InProgress]: {
        title: 'Ремонт',
        color: 'secondary',
        colorHex: '#9C78DD66',
        colorText: '#5E35B1',
        workerIssueStatus: workerIssueStatusMock.assign,
        triggers: [
            EIssueTriggers.ChangeAppointmentDatetime,
            EIssueTriggers.Done,
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.MasterArrived,
            EIssueTriggers.Processed,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.UpdateAddress,
            EIssueTriggers.SavePrice,
            EIssueTriggers.Solved,
        ],
        activePutStatuses: [
            EIssueStatus.PartsSearch,
            EIssueStatus.MasterOnWay,
            EIssueStatus.WaitingForReceipt,
            EIssueStatus.WaitingCall,
            EIssueStatus.Processed,
            EIssueStatus.Canceled,
            EIssueStatus.ActNRP,
        ],
        archivePutStatuses: [EIssueStatus.MasterSearch, EIssueStatus.WaitingForReceipt],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.Solved]: {
        title: 'Выполнено',
        color: 'success',
        colorHex: '#5FEA7E66',
        colorText: '#388E3C',
        workerIssueStatus: null,
        triggers: [EIssueTriggers.SetIsArchivedTrue],
        activePutStatuses: [],
        archivePutStatuses: [EIssueStatus.PaymentRequired],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.Canceled]: {
        title: 'Отказ',
        color: 'error',
        colorHex: '#F6609266',
        colorText: '#D81B60',
        workerIssueStatus: workerIssueStatusMock.notAssign,
        triggers: [EIssueTriggers.MasterSearch, EIssueTriggers.SetIsArchivedTrue],
        activePutStatuses: [],
        archivePutStatuses: [EIssueStatus.MasterSearch],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.SolvedWithProblem]: {
        title: 'Решено с проблемой',
        color: 'error',
        colorHex: '#F6609266',
        colorText: '#D81B60',
        workerIssueStatus: workerIssueStatusMock.assign,
        triggers: [EIssueTriggers.SetIsArchivedTrue],
        activePutStatuses: [],
        archivePutStatuses: [EIssueStatus.PaymentRequired],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.MasterArrived]: {
        title: 'Мастер прибыл',
        color: 'secondary',
        colorHex: '#9C78DD66',
        colorText: '#5E35B1',
        workerIssueStatus: workerIssueStatusMock.assign,
        triggers: [
            EIssueTriggers.Done,
            EIssueTriggers.CancelClient,
            EIssueTriggers.InProgress,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.SavePrice,
            EIssueTriggers.Solved,
        ],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.WaitingForReceipt,
            EIssueStatus.WaitingForCash,
            EIssueStatus.PaymentRequired,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: [EIssueStatus.MasterSearch, EIssueStatus.InProgress],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.MasterOnWay]: {
        title: 'Мастер в пути',
        color: 'secondary',
        colorHex: '#9C78DD66',
        colorText: '#5E35B1',
        workerIssueStatus: workerIssueStatusMock.assign,
        triggers: [
            EIssueTriggers.Done,
            EIssueTriggers.CancelClient,
            EIssueTriggers.InProgress,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.SavePrice,
            EIssueTriggers.Solved,
        ],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.WaitingForReceipt,
            EIssueStatus.WaitingForCash,
            EIssueStatus.PaymentRequired,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: [EIssueStatus.MasterSearch, EIssueStatus.InProgress],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.WaitingApprovedByClient]: {
        title: 'Подтверждение клиента',
        color: 'default',
        colorHex: '#EFAD4B66',
        colorText: '#EF6C00',
        workerIssueStatus: null,
        triggers: [EIssueTriggers.CancelClient, EIssueTriggers.InProgress, EIssueTriggers.MasterSearch],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.PartsSearch,
            EIssueStatus.MasterSearch,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: null,
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.ProblemByClient]: {
        title: 'Проблемная заявка',
        color: 'warning',
        colorHex: '#F6609266',
        colorText: '#D81B60',
        workerIssueStatus: null,
        triggers: [
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.InProgress,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.SavePrice,
        ],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.PartsSearch,
            EIssueStatus.MasterSearch,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: null,
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.WaitingApprovedByMaster]: {
        title: 'Уточнение у мастера',
        color: 'primary',
        colorHex: '#9C78DD66',
        colorText: '#5E35B1',
        workerIssueStatus: null,
        triggers: [
            EIssueTriggers.ChangeAppointmentDatetime,
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.UpdateAddress,
        ],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.PartsSearch,
            EIssueStatus.MasterSearch,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: null,
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.ProblemByMaster]: {
        title: 'Проблемная заявка',
        color: 'error',
        colorHex: '#F48FB166',
        colorText: '#D81B60',
        workerIssueStatus: workerIssueStatusMock.notApproved,
        triggers: [
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.Accept,
            EIssueTriggers.InProgress,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.SavePrice,
        ],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.PartsSearch,
            EIssueStatus.MasterSearch,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: null,
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.WaitingCall]: {
        title: 'Ожидает звонка',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: null,
        triggers: [
            EIssueTriggers.CancelAdmin,
            EIssueTriggers.Accept,
            EIssueTriggers.InProgress,
            EIssueTriggers.MasterSearch,
            EIssueTriggers.SavePrice,
        ],
        activePutStatuses: [
            EIssueStatus.Processed,
            EIssueStatus.InProgress,
            EIssueStatus.PartsSearch,
            EIssueStatus.ActNRP,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: null,
        isCanPutActiveStatus: (issue, newStatus) => {
            if (newStatus === EIssueStatus.Processed && issue.worker) return false;
            if (newStatus === EIssueStatus.InProgress && !issue.worker) return false;
            return isCanPutActiveStatusDefault(issue, newStatus);
        },
    },
    [EIssueStatus.WaitingForCash]: {
        title: 'Ожидает наличных',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: null,
        triggers: [EIssueTriggers.MasterArrived, EIssueTriggers.WaitingForReceipt],
        activePutStatuses: [EIssueStatus.InProgress, EIssueStatus.Solved, EIssueStatus.Canceled],
        archivePutStatuses: null,
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.PartsSearch]: {
        title: 'Поиск запчастей',
        color: 'primary',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: null,
        triggers: [EIssueTriggers.MasterArrived, EIssueTriggers.WaitingForReceipt],
        activePutStatuses: [EIssueStatus.MasterSearch],
        archivePutStatuses: null,
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.PartsDelivery]: {
        title: 'Доставка запчастей',
        color: 'success',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: null,
        triggers: [EIssueTriggers.MasterArrived, EIssueTriggers.WaitingForReceipt],
        activePutStatuses: [EIssueStatus.MasterSearch, EIssueStatus.WaitingCall, EIssueStatus.Processed],
        archivePutStatuses: null,
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.PartsRequest]: {
        title: 'Запрос запчастей',
        color: 'success',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: null,
        triggers: [EIssueTriggers.MasterArrived, EIssueTriggers.WaitingForReceipt],
        activePutStatuses: [EIssueStatus.PartsSearch],
        archivePutStatuses: null,
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
    [EIssueStatus.SolvedNotPartner]: {
        title: 'Выполнено (не у партнера)',
        color: 'success',
        colorHex: '#5FEA7E66',
        colorText: '#388E3C',
        workerIssueStatus: null,
        triggers: [],
        activePutStatuses: [EIssueStatus.Solved],
        archivePutStatuses: [],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },

    [EIssueStatus.Clarification]: {
        title: 'Уточнение',
        color: 'success',
        colorHex: '#EFAD4B66',
        colorText: '#EF6C00',
        workerIssueStatus: null,
        triggers: [],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.WaitingCall,
            EIssueStatus.PartsSearch,
            EIssueStatus.PaymentRequired,
            EIssueStatus.SolvedNotPartner,
            EIssueStatus.ActNRP,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: [],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },

    [EIssueStatus.Diagnostic]: {
        title: 'Диагностика',
        color: 'success',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: null,
        triggers: [],
        activePutStatuses: [
            EIssueStatus.InProgress,
            EIssueStatus.PaymentRequired,
            EIssueStatus.SolvedNotPartner,
            EIssueStatus.Solved,
        ],
        archivePutStatuses: [],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },

    [EIssueStatus.ActNRP]: {
        title: 'Акт НРП',
        color: 'success',
        colorHex: '#5CB2F666',
        colorText: '#2962FF',
        workerIssueStatus: null,
        triggers: [],
        activePutStatuses: [
            EIssueStatus.WaitingCall,
            EIssueStatus.InProgress,
            EIssueStatus.PartsSearch,
            EIssueStatus.SolvedNotPartner,
            EIssueStatus.Solved,
            EIssueStatus.Canceled,
        ],
        archivePutStatuses: [],
        isCanPutActiveStatus: isCanPutActiveStatusDefault,
    },
};

export type TIssueStatusLocal = Record<
    EIssueStatus,
    {
        title: string;
        color: ChipTypeMap['props']['color'];
        colorHex: string;
        colorText: string;
        workerIssueStatus?: { title: string; color: string; icon: ReactNode } | null;
        triggers: EIssueTriggers[];
        activePutStatuses: EIssueStatus[];
        archivePutStatuses: EIssueStatus[] | null;
        isCanPutActiveStatus: (issue: IIssue, newStatus: EIssueStatus) => boolean;
    }
>;

export const defaultTableHeadMock: IIssuesTableHead[] = [
    {
        order: 'asc',
        property: 'oid',
        title: '№',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'client',
        title: 'Клиент',
        isSorted: false,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: EIssueTableHeadFilter.Category,
        title: 'Категории',
        isSorted: false,
        padding: 'normal',
        isFilter: true,
    },
    {
        order: 'asc',
        property: EIssueTableHeadFilter.HelpType,
        title: 'Тип',
        isSorted: false,
        padding: 'normal',
        isFilter: true,
    },
    {
        order: 'asc',
        property: EIssueTableHeadFilter.Status,
        title: 'Статус',
        isSorted: false,
        padding: 'none',
        isFilter: true,
    },
    {
        order: 'asc',
        property: 'created_at',
        title: 'Дата создания',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'time_window',
        title: 'Дата вызова',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'worker',
        title: 'Мастер',
        isSorted: false,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: EIssueTableHeadFilter.Companies,
        title: 'Компании',
        isSorted: false,
        padding: 'normal',
        isFilter: true,
    },
    {
        order: 'asc',
        property: EIssueTableHeadFilter.Region,
        title: 'Регион',
        isSorted: true,
        padding: 'normal',
        isFilter: true,
    },
    {
        order: 'asc',
        property: 'unseen_count',
        title: 'Чат',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: EIssueTableHeadFilter.Reminder,
        title: <NotificationsIcon color="primary" />,
        isSorted: false,
        padding: 'normal',
        isFilter: true,
    },
    {
        order: 'asc',
        property: 'observer',
        title: <VisibilityIcon color="primary" />,
        isSorted: false,
        padding: 'normal',
        isFilter: false,
    },
];

export const archiveTableHeadMock: IIssuesTableHead[] = [
    {
        order: 'asc',
        property: 'oid',
        title: '№',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'client',
        title: 'Клиент',
        isSorted: false,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'help_type',
        title: 'Тип',
        isSorted: false,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'status',
        title: 'Статус',
        isSorted: false,
        padding: 'none',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'created_at',
        title: 'Дата создания',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'time_window',
        title: 'Дата вызова',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'worker',
        title: 'Мастер',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'created_source',
        title: 'Источник',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'solved',
        title: 'Оплата',
        isSorted: false,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'region',
        title: 'Регион',
        isSorted: false,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'unseen_count',
        title: 'Чат',
        isSorted: true,
        padding: 'normal',
        isFilter: false,
    },
    {
        order: 'asc',
        property: 'reminder',
        title: 'Напоминание',
        isSorted: false,
        padding: 'normal',
        isFilter: false,
    },
];

export const dateOfCreatingFiltersMock: IFilter[] = [
    {
        id: 'today',
        title: 'Сегодня',
    },
    {
        id: 'yesterday',
        title: 'Вчера',
    },
    {
        id: 'period',
        title: 'Период',
    },
];

export const dateCallFiltersMock: IFilter[] = [
    {
        id: 'today',
        title: 'Сегодня',
    },
    {
        id: 'tomorrow',
        title: 'Завтра',
    },
    {
        id: 'period',
        title: 'Период',
    },
];

export const dateOfLastMessageFiltersMock: IFilter[] = [
    {
        id: 'today',
        title: 'Сегодня',
    },
    {
        id: 'yesterday',
        title: 'Вчера',
    },
    {
        id: 'period',
        title: 'Период',
    },
];

export const appTypeFiltersMock: IFilter[] = [
    {
        id: 'client',
        title: 'Клиент',
    },
    {
        id: 'worker',
        title: 'Мастер',
    },
];

export const statusFiltersMock = [
    {
        id: 'in_work',
        statuses: [
            EIssueStatus.Processed,
            EIssueStatus.MasterSearch,
            EIssueStatus.MasterNotFound,
            EIssueStatus.MasterArrived,
            EIssueStatus.InProgress,
            EIssueStatus.SolvedWithProblem,
            EIssueStatus.PaymentRequired,
            EIssueStatus.WaitingForReceipt,
            EIssueStatus.WaitingApprovedByClient,
            EIssueStatus.ProblemByClient,
            EIssueStatus.WaitingApprovedByMaster,
            EIssueStatus.ProblemByMaster,
            EIssueStatus.WaitingForCash,
            EIssueStatus.MasterOnWay,
            EIssueStatus.Diagnostic,
        ],
        title: 'В работе',
        color: '#3A57E8',
        count: null,
        isActive: true,
    },
    {
        id: 'new',
        statuses: [EIssueStatus.New],
        title: 'Новые',
        color: '#08B1BA',
        count: null,
        isActive: false,
    },
    {
        id: 'accept',
        statuses: [EIssueStatus.WaitingApprovedByClient, EIssueStatus.WaitingApprovedByMaster],
        title: 'Подтверждение',
        color: '#FFD200',
        count: null,
        isActive: false,
    },
    {
        id: 'waiting_call',
        statuses: [EIssueStatus.WaitingCall],
        title: 'Ожидает звонка',
        color: '#2962FF',
        count: null,
        isActive: false,
    },
    {
        id: 'without_master',
        statuses: [EIssueStatus.Processed, EIssueStatus.MasterNotFound, EIssueStatus.MasterSearch],
        title: 'Без мастера',
        color: '#D32F2F',
        count: null,
        isActive: false,
    },
    {
        id: 'confirmation',
        statuses: [
            EIssueStatus.SolvedNotPartner,
            EIssueStatus.WaitingForCash,
            EIssueStatus.WaitingForReceipt,
            EIssueStatus.PaymentRequired,
        ],
        title: 'Ожидает оплаты',
        color: '#F16A1B',
        count: null,
        isActive: false,
    },
    {
        id: 'problem',
        statuses: [EIssueStatus.ProblemByClient, EIssueStatus.ProblemByMaster],
        title: 'Проблемные',
        color: '#C03221',
        count: null,
        isActive: false,
    },
    {
        id: 'part_orders',
        statuses: [EIssueStatus.PartsSearch, EIssueStatus.PartsDelivery, EIssueStatus.PartsRequest],
        title: 'Запчасти',
        color: '#2962FF',
        count: null,
        isActive: false,
    },
    {
        id: 'clarification',
        statuses: [EIssueStatus.Clarification],
        title: 'Уточнение',
        color: '#FFD200',
        count: null,
        isActive: false,
    },
    {
        id: 'act_nrp',
        statuses: [EIssueStatus.ActNRP],
        title: 'Акт НРП',
        color: '#3A57E8',
        count: null,
        isActive: false,
    },
    {
        id: 'rejected',
        statuses: [EIssueStatus.Canceled],
        title: 'Отказы',
        color: '#D32F2F',
        count: null,
        isActive: false,
    },
    {
        id: 'solved',
        statuses: [EIssueStatus.Solved],
        title: 'Выполнены',
        color: '#388E3C',
        count: null,
        isActive: false,
    },
] as IStatusFilter[];

export const chatTypesIssueMock: {
    [key in EChatUserTypes.Client | EChatUserTypes.Worker | EChatTypes.IssueRelated]: IChatTypeIssue;
} = {
    client: {
        tabId: '0',
        title: 'С клиентом',
        chatType: EChatTypes.Support,
        user: {
            type: EChatUserTypes.Client,
        },
        isDisabled: true,
    },
    worker: {
        tabId: '1',
        title: 'С мастером',
        chatType: EChatTypes.Support,
        user: {
            type: EChatUserTypes.Client,
        },
        isDisabled: true,
    },
    issue_related: {
        tabId: '2',
        title: 'Клиент мастер',
        chatType: EChatTypes.IssueRelated,
        isDisabled: true,
    },
};

export const issuePriceEditingAllowedStatuses: EIssueStatus[] = [
    EIssueStatus.Processed,
    EIssueStatus.SolvedWithProblem,
    EIssueStatus.New,
    EIssueStatus.MasterNotFound,
    EIssueStatus.MasterSearch,
    EIssueStatus.MasterArrived,
    EIssueStatus.InProgress,
    EIssueStatus.WaitingForReceipt,
    EIssueStatus.ProblemByClient,
    EIssueStatus.ProblemByMaster,
    EIssueStatus.WaitingCall,
    EIssueStatus.SolvedNotPartner,
    EIssueStatus.ActNRP,
];

export const issueWarrantyEditingAllowedStatuses: EIssueStatus[] = [
    EIssueStatus.New,
    EIssueStatus.Processed,
    EIssueStatus.WaitingCall,
    EIssueStatus.InProgress,
    EIssueStatus.MasterSearch,
    EIssueStatus.MasterArrived,
    EIssueStatus.MasterOnWay,
    EIssueStatus.PartsSearch,
    EIssueStatus.PartsDelivery,
];

export const issueAddressEditingAllowed: EIssueStatus[] = [
    EIssueStatus.New,
    EIssueStatus.Processed,
    EIssueStatus.MasterSearch,
    EIssueStatus.WaitingApprovedByMaster,
    EIssueStatus.InProgress,
    EIssueStatus.WaitingCall,
];

export const issueDateEditingAllowed: EIssueStatus[] = [
    EIssueStatus.New,
    EIssueStatus.Processed,
    EIssueStatus.MasterSearch,
    EIssueStatus.WaitingApprovedByMaster,
    EIssueStatus.InProgress,
    EIssueStatus.WaitingCall,
    EIssueStatus.PartsSearch,
    EIssueStatus.PartsDelivery,
];

export const issueHelpTypeEditingAllowed: EIssueHelpType[] = [EIssueHelpType.Repair, EIssueHelpType.Installation];

export const issueEditingPaymentMethod: EIssueStatus[] = [EIssueStatus.MasterArrived, EIssueStatus.WaitingForReceipt];

export const issueCreateOrderAllowed: EIssueStatus[] = [
    EIssueStatus.Processed,
    EIssueStatus.MasterSearch,
    EIssueStatus.MasterNotFound,
    EIssueStatus.MasterArrived,
    EIssueStatus.InProgress,
    EIssueStatus.SolvedWithProblem,
    EIssueStatus.WaitingCall,
    EIssueStatus.WaitingApprovedByClient,
    EIssueStatus.ProblemByClient,
    EIssueStatus.WaitingApprovedByMaster,
    EIssueStatus.ProblemByMaster,
    EIssueStatus.PartsSearch,
    EIssueStatus.PartsDelivery,
];

export const PaymentMethodMock = {
    [EPaymentMethod.Cash]: 'Наличные',
    [EPaymentMethod.WithoutCash]: 'Безналичные',
};

export type TIssueMasterSearchStatus = Record<
    EAutoAssignmentStatus,
    {
        title: string;
        color: string;
        // eslint-disable-next-line no-undef
        icon: JSX.Element;
    }
>;

export const ErrorsMasterSearchMock = {
    [EAutoAssignmentErrors.BySchedule]: {
        title: 'Мастер не подобран по дате вызова',
    },
    [EAutoAssignmentErrors.BySpecializations]: {
        title: 'Мастер не подобран по специализации',
    },
    [EAutoAssignmentErrors.ByServiceAreas]: {
        title: 'Мастер не подобран по зонам обслуживаниям',
    },
    [EAutoAssignmentErrors.ByJoin]: {
        title: 'Ни один мастер не удовлетворил сразу всем условиям: дата вызова, специализация, зона обслуживаниям',
    },
    [EAutoAssignmentErrors.BySelectionTime]: {
        title: 'Время подбора мастера истекло',
    },
    [EAutoAssignmentErrors.ByAllWorkerHide]: {
        title: 'Все мастера отказались от заявки',
    },
    [EAutoAssignmentErrors.ByTimeWindow]: {
        title: 'Не указана дата вызова',
    },
};

export const CategoryPresetMock = [
    {
        value: ECategoryPreset.SplitSystem,
        label: 'Сплит-системы',
    },
    {
        value: ECategoryPreset.Other,
        label: 'Другое',
    },
];
export const ObserversMock = [
    {
        value: EObserversFilter.CurrentUser,
        label: 'Текущий пользователь',
    },
    {
        value: EObserversFilter.Unassigned,
        label: 'Не назначено',
    },
];
