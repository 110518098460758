import { Button, styled } from '@mui/material';

export const BasicsWrapper = styled('div')({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
});

export const IconWrapper = styled('div')({
    position: 'absolute',
    right: '-5px',
    top: '-30px',
    cursor: 'pointer',
});

export const ButtonItem = styled(Button)({
    textTransform: 'none',
    display: 'flex',
    justifyContent: 'flex-start',
    padding: '5px 14px',
    '.MuiButtonBase-root': {},
}); // double
