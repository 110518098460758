import { servicesApi } from 'api';
import { fetchServiceById, setServices } from 'pages/services/store';
import { AddGroup } from 'static/Icons/AddGroup';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { EllipseGreen } from 'static/Icons/EllipseGreen';
import { ExpandIcon } from 'static/Icons/ExpandIcon';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';

import { Box, Typography } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import {
    Badge,
    CategoriesContainer,
    CategoryItem,
    ChildWrapper,
    CloseIconWrapper,
    IconWrapper,
    Service,
    ServiceWrapper,
    CategoriesWrapper,
    MuiPopover,
    CategoriesBox,
    ExpandIconWrapper,
} from './styles';

type TItem = {
    id: string;
    categories: any[];
    children: any[];
    category_ids: any[];
    description: string;
    is_active: boolean;
    is_removed: boolean;
    is_root: boolean;
    name: string;
    parents: unknown;
    service_types: any[];
    created_at: string;
    updated_at: string;
    has_children: boolean;
};

type Props = {
    item: TItem;
    setOpenAddModal: (value: boolean) => void;
    setOpenEditModal: (value: boolean) => void;
};

export const ServiceItem: FC<Props> = ({ item, setOpenAddModal, setOpenEditModal }) => {
    const dispatch = useAppDispatch();

    const { services } = useSelector(servicesSelector);

    const [isExpand, setExpand] = useState(false);
    const [openServices, setOpenServices] = useState<any>(false);
    const [openCategories, setOpenCategories] = useState<any>(false);

    const [loading, setLoading] = useState<boolean>(false);

    const getChildren = () => {
        if (item.children.length) return;

        setLoading(true);
        servicesApi
            .getServiceById(item.id)
            .then(({ data }) => {
                const setByChild = <T extends { id: string; children: T[] }>(array: T[]): T[] => {
                    return array.map((service) => {
                        if (service.id === item?.id) {
                            return { ...service, children: data.children };
                        }

                        if (service.id !== item.id && service.children.length) {
                            return { ...service, children: setByChild(service.children) };
                        }

                        return service;
                    });
                };

                dispatch(setServices(setByChild(services)));
            })
            .finally(() => setLoading(false));
    };

    const isServicesShouldOpening = item.service_types?.length > 1;
    const isCategoriesShouldOpening = item.categories?.length > 1;

    const filteredServices = item.service_types?.filter((item: any, idx: number) => idx > 0 && item);
    const filteredCategories = item.categories?.filter((item: any, idx: number) => idx > 0 && item);

    return (
        <div>
            <Service
                onClick={() => {
                    dispatch(fetchServiceById(item.id)).then(() => {
                        setOpenEditModal(true);
                    });
                }}
            >
                <ServiceWrapper>
                    {item?.has_children ? (
                        <ExpandIconWrapper
                            isOpen={isExpand}
                            onClick={(e) => {
                                e.stopPropagation();
                                getChildren();
                                setExpand((prev) => !prev);
                            }}
                        >
                            {loading ? <CircularProgress size="15px" /> : <ExpandIcon />}
                        </ExpandIconWrapper>
                    ) : null}
                    <EllipseGreen status={item.is_active} />
                    <Typography sx={{ fontSize: '14px' }}>{item.name}</Typography>
                </ServiceWrapper>
                <CategoriesContainer>
                    <CategoriesBox
                        isShouldOpening={isServicesShouldOpening}
                        isActive={openServices}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenServices(e.currentTarget);
                        }}
                    >
                        <span>{item?.service_types?.[0] ? item?.service_types?.[0]?.name : 'Все услуги'}</span>
                        {isServicesShouldOpening ? (
                            <Badge isActive={openServices}>+ {filteredServices?.length}</Badge>
                        ) : null}
                    </CategoriesBox>
                    <CategoriesBox
                        isShouldOpening={isCategoriesShouldOpening}
                        isActive={openCategories}
                        onClick={(e) => {
                            e.stopPropagation();
                            setOpenCategories(e.currentTarget);
                        }}
                    >
                        <span>{item?.categories?.[0] ? item?.categories?.[0]?.name : 'Все категории'}</span>
                        {isCategoriesShouldOpening ? (
                            <Badge isActive={openCategories}>+ {filteredCategories?.length}</Badge>
                        ) : null}
                    </CategoriesBox>

                    {isServicesShouldOpening ? (
                        <MuiPopover
                            open={openServices}
                            anchorEl={openServices}
                            onClose={(event) => {
                                (event as Event).stopPropagation();
                                setOpenServices(false);
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box>
                                <CloseIconWrapper
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setOpenServices(false);
                                    }}
                                >
                                    <CloseIcon />
                                </CloseIconWrapper>
                                <div style={{ color: '#BCBFD1' }}>Типы услуг</div>
                                <CategoriesWrapper>
                                    {item?.service_types?.map((serviceType: any) => {
                                        return <CategoryItem key={serviceType.id}>{serviceType.name}</CategoryItem>;
                                    })}
                                </CategoriesWrapper>
                            </Box>
                        </MuiPopover>
                    ) : null}
                    {isCategoriesShouldOpening ? (
                        <MuiPopover
                            open={openCategories}
                            anchorEl={openCategories}
                            onClose={(event) => {
                                (event as Event).stopPropagation();
                                setOpenCategories(false);
                            }}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Box>
                                <CloseIconWrapper
                                    onClick={(event) => {
                                        event.stopPropagation();
                                        setOpenCategories(false);
                                    }}
                                >
                                    <CloseIcon />
                                </CloseIconWrapper>
                                <div style={{ color: '#BCBFD1' }}>Категории техники</div>
                                <CategoriesWrapper>
                                    {item?.categories?.map((category: any) => {
                                        return <CategoryItem key={category.id}>{category.name}</CategoryItem>;
                                    })}
                                </CategoriesWrapper>
                            </Box>
                        </MuiPopover>
                    ) : null}
                </CategoriesContainer>
                <IconWrapper
                    onClick={(e) => {
                        e.stopPropagation();
                        dispatch(fetchServiceById(item.id)).then(() => {
                            setOpenAddModal(true);
                        });
                    }}
                >
                    <AddGroup />
                </IconWrapper>
            </Service>
            {item?.children?.length
                ? item.children.map((child: any) => {
                      return (
                          <ChildWrapper
                              isOpen={isExpand}
                              key={child.id}
                          >
                              <ServiceItem
                                  setOpenAddModal={setOpenAddModal}
                                  setOpenEditModal={setOpenEditModal}
                                  item={child}
                              />
                          </ChildWrapper>
                      );
                  })
                : null}
        </div>
    );
};
