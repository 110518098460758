import { createSelector } from 'reselect';

import { RootState } from '../../rootReducer';

const state = ({ editIssuePrices }: RootState) => editIssuePrices;

export const isOpenEditIssuePricesSelector = createSelector(state, ({ isOpen }) => isOpen);
export const isOpenEditIssuePricesClientSelector = createSelector(state, ({ isOpenClient }) => isOpenClient);
export const issueIdEditIssuePricesSelector = createSelector(state, ({ issueId }) => issueId);
export const staticPricesEditIssuePricesSelector = createSelector(state, ({ staticPrices }) => staticPrices);
export const formValuesEditIssuePricesSelector = createSelector(state, ({ formValues }) => formValues);
export const errorsEditIssuePricesSelector = createSelector(state, ({ errors }) => errors);
export const allPricesEditIssuePricesSelector = createSelector(state, ({ allPrices }) => allPrices);
export const searchPricesOptionsEditIssuePricesSelector = createSelector(state, ({ allPrices }) => {
    return (
        allPrices?.map(({ id, name, categories, ...rest }) => {
            if ('service' in rest) {
                return {
                    value: id,
                    label: name,
                    amount: rest.ratios?.total_ratio_amount,
                    categoryName: categories && categories[0].name,
                    serviceName: rest.service.name,
                    isAdditional: rest.is_additional,
                };
            } else {
                return {
                    value: id,
                    label: name,
                    amount: rest.amount,
                    categoryName: categories && categories[0].name,
                    serviceName: rest.service_type.name,
                    isAdditional: false,
                };
            }
        }) || null
    );
});
export const priceRecipientEditIssuePricesSelector = createSelector(state, ({ priceRecipient }) => priceRecipient);
export const dialogTitleEditIssuePricesSelector = createSelector(state, ({ dialogTitle }) => dialogTitle);
