import { priceListsApi } from 'api';
import { ERoutes } from 'enums/routes.enum';
import { fetchPriceLists, setPriceList } from 'pages/price-lists/store';
import { EllipseGreen } from 'static/Icons/EllipseGreen';
import { ThreeDots } from 'static/Icons/ThreeDots';
import { useAppDispatch } from 'store/hooks';

import { Popover, TableBody, TableRow, Tooltip } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { ButtonItem, Cell } from './styles';

import { CopyModal } from '../modals';

type Props = {
    rows: any[];
};

export const Body: FC<Props> = ({ rows }) => {
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const [activePriceListId, setActivePriceListId] = useState<string>();

    const [isOpen, setOpen] = useState<any>(false);
    const [isOpenCopyModal, setOpenCopyModal] = useState<any>(false);

    const handleDelete = () => {
        if (!activePriceListId) return;

        priceListsApi
            .deletePriceList(activePriceListId)
            .then(() => {
                setOpen(false);
                dispatch(fetchPriceLists());

                enqueueSnackbar('Прайс-лист удален', { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    const handleCopy = () => {
        dispatch(setPriceList(rows.find((item) => item.id === activePriceListId)));
        setOpenCopyModal(true);
        setOpen(false);
    };

    const handleActivation = (value: boolean) => {
        priceListsApi
            .editPriceList(activePriceListId, { is_active: value })
            .then(() => {
                setOpen(false);
                dispatch(fetchPriceLists());

                enqueueSnackbar(`Прайс-лист ${value ? 'активирован' : 'деактивирован'}`, { variant: 'success' });
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            });
    };

    return (
        <TableBody>
            {rows.map((item) => {
                const title =
                    (item.is_active ? 'Активный' : 'Неактивный') +
                    (item.has_empty_services_amount ? ', не указана цена' : '') +
                    (item.has_empty_services_commissions ? ', не указана комиссия мастера' : '');

                return (
                    <>
                        <TableRow
                            key={item.id}
                            sx={{ cursor: 'pointer' }}
                            onClick={() => navigate(`${item.id}/${ERoutes.PriceListMain}`)}
                        >
                            <Cell sx={{ textAlign: 'start', width: '30%' }}>
                                <Link to={`${item.id}`}>{item.system_name}</Link>
                            </Cell>
                            <Cell sx={{ textAlign: 'start', width: '30%' }}>{item.name}</Cell>
                            <Cell>{item.company?.name}</Cell>
                            <Cell>{item.service_type?.name}</Cell>
                            <Cell>{item.region?.title}</Cell>
                            <Cell sx={{ position: 'relative', paddingRight: 0 }}>
                                <Tooltip
                                    title={title}
                                    arrow
                                >
                                    <span>
                                        <EllipseGreen status={item.is_active} />
                                    </span>
                                </Tooltip>
                            </Cell>
                            <Cell
                                sx={{ paddingLeft: '10px' }}
                                onClick={(e) => {
                                    e.stopPropagation();
                                    setOpen(e.currentTarget);
                                    setActivePriceListId(item.id);
                                }}
                            >
                                <span style={{ cursor: 'pointer', display: 'flex', alignItems: 'center' }}>
                                    <ThreeDots />
                                </span>
                            </Cell>
                        </TableRow>
                    </>
                );
            })}

            <Popover
                onClose={() => setOpen(false)}
                open={isOpen}
                anchorEl={isOpen}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                sx={{ '.MuiPaper-root': { borderRadius: '8px' } }}
            >
                <div style={{ display: 'flex', flexDirection: 'column', padding: '10px 0' }}>
                    <ButtonItem
                        color="inherit"
                        onClick={handleCopy}
                    >
                        Копировать прайс-лист
                    </ButtonItem>
                    {rows.map((row) => {
                        if (row.id === activePriceListId && !row.is_active) {
                            return (
                                <ButtonItem
                                    key={row.id}
                                    onClick={() => handleActivation(true)}
                                    color="inherit"
                                >
                                    Активировать
                                </ButtonItem>
                            );
                        } else if (row.id === activePriceListId && row.is_active) {
                            return (
                                <ButtonItem
                                    key={row.id}
                                    onClick={() => handleActivation(false)}
                                    color="inherit"
                                >
                                    Деактивировать
                                </ButtonItem>
                            );
                        }
                    })}

                    <ButtonItem
                        color="error"
                        onClick={handleDelete}
                    >
                        Удалить прайс-лист
                    </ButtonItem>
                </div>
            </Popover>

            {isOpenCopyModal && <CopyModal setOpen={setOpenCopyModal} />}
        </TableBody>
    );
};
