import { TPriceListService } from 'pages/price-lists/store/types';

import { AxiosPromise } from 'axios';
import { IPaginationResp } from 'interfaces/pagination';

import axiosFetch from './axiosFetch';

export default class servicesPriceListsAPI {
    getServicesComplexWithOutPaginate(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<TPriceListService[]>;
    }

    getServicesComplex(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<IPaginationResp<TPriceListService>>;
    }

    getServiceComplex(id: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<TPriceListService>;
    }

    createServicesComplex(data: any) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    editServicesComplex(id: string, data: any) {
        return axiosFetch({
            method: 'patch',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices/${id}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    deleteServicesComplex(id: string) {
        return axiosFetch({
            method: 'delete',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices/${id}`,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    editAmountBulkUpdate(serviceId: string, data: { value: string }) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices/amountBulkUpdate/${serviceId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    editCommissionBulkUpdate(serviceId: string, data: { value: string }) {
        return axiosFetch({
            method: 'put',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/priceListServices/commissionBulkUpdate/${serviceId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }
}
