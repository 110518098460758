import { AxiosPromise } from 'axios';
import { IPaginationResp } from 'interfaces/pagination';
import { IClientTag } from 'interfaces/tags';

import axiosFetch from './axiosFetch';

export default class ClientTagsAPI {
    getTags(params: any) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clientTags`,
            params,
            isAuthorization: true,
        }) as AxiosPromise<IPaginationResp<IClientTag>>;
    }

    getClientTags(clientId: string) {
        return axiosFetch({
            method: 'get',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clientClientsTags/${clientId}`,
            isAuthorization: true,
        }) as AxiosPromise<IClientTag[]>;
    }

    editClientTags(clientId: string, data: string[]) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clientClientsTags/${clientId}`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }

    deleteClientTag(clientId: string, data: string[]) {
        return axiosFetch({
            method: 'post',
            url: `${process.env.REACT_APP_API}/${process.env.REACT_APP_API_VERSION}/clientClientsTags/${clientId}/delete`,
            data,
            isAuthorization: true,
        }) as AxiosPromise<any>;
    }
}
