import { CloseIcon } from 'static/Icons/CloseIcon';

import { FC, useState } from 'react';

import { Basics } from './basics';
import { CloseIconWrapper, Header, ModalWrapper, MuiDivider, MuiTab, MuiTabs } from './styles';

type Props = {
    setOpen: (isOpen: boolean) => void;
    setOpenEditModal: (isOpen: boolean) => void;
};

export const CreateService: FC<Props> = ({ setOpen, setOpenEditModal }) => {
    const [value, setValue] = useState<any>(0);

    const handleChangeTabs = (_: any, value: any) => {
        setValue(value);
    };

    const render = (value: number) => {
        switch (value) {
            case 0:
                return (
                    <Basics
                        setOpenEditModal={setOpenEditModal}
                        setOpen={setOpen}
                    />
                );
        }
    };

    return (
        <ModalWrapper>
            <Header>
                <MuiTabs
                    onChange={handleChangeTabs}
                    value={value}
                >
                    <MuiTab label="Основное" />
                    <MuiTab
                        disabled
                        label="Прайс-листы"
                    />
                    <MuiTab
                        disabled
                        label="Внешние интеграции"
                    />
                </MuiTabs>
                <CloseIconWrapper onClick={() => setOpen(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
            </Header>
            <MuiDivider sx={{ color: 'rgba(0, 0, 0, 0.08)', marginBottom: '20px' }} />
            <div>{render(value)}</div>
        </ModalWrapper>
    );
};
