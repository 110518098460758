import FileUploadButton from 'components/FileUploadButton/fileUploadButton';
import { EIssueStatus } from 'enums/issues.enum';
import { changeIssueActNRPThunk } from 'store/actions/issues';
import { useAppDispatch } from 'store/hooks';
import { issueSelector } from 'store/selectors/issuesSelectors';
import defaultErrorCallback from 'utils/helpers/defaultErrorCallback';
import { getErrorString } from 'utils/helpers/getErrorString';

import BrowserNotSupportedIcon from '@mui/icons-material/BrowserNotSupported';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import DeleteIcon from '@mui/icons-material/Delete';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import FileOpenIcon from '@mui/icons-material/FileOpen';
import { IconButton, Link } from '@mui/material';
import { IPhotoFile } from 'interfaces';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import './ActNrp.scss';

interface IProps {
    actNRP: IPhotoFile | null | undefined;
}

interface IEditProp extends IProps {
    handleChanged: (id: string | null) => void;
}

function ActNRPPreview({ actNRP }: IProps) {
    const preview = actNRP?.previews && actNRP.previews[0];
    return (
        <div className="dv-issue-act-nrp-preview">
            {!actNRP ? (
                <div className="dv-issue-act-nrp-preview-icon">
                    <BrowserNotSupportedIcon />
                </div>
            ) : (
                <Link
                    target="_blank"
                    href={actNRP.url}
                >
                    {preview ? (
                        <img
                            alt="Акт НРП"
                            src={preview.url}
                        />
                    ) : (
                        <div className="dv-issue-act-nrp-preview-icon">
                            <FileOpenIcon />
                        </div>
                    )}
                </Link>
            )}
        </div>
    );
}

function ActNRPEdit({ actNRP, handleChanged }: IEditProp) {
    const [actNRPFile, setActNrpFIle] = useState<IPhotoFile | null | undefined>(actNRP);

    function fileUpload(file: IPhotoFile) {
        setActNrpFIle(file);
    }

    function clearActNrp() {
        setActNrpFIle(null);
    }

    useEffect(() => {
        handleChanged(actNRPFile?.id || null);
    }, [actNRPFile]);

    return (
        <div className="dv-issue-act-nrp-edit">
            <ActNRPPreview actNRP={actNRPFile} />
            {actNRPFile && (
                <IconButton
                    className="dv-issue-act-nrp-edit-clear"
                    onClick={clearActNrp}
                    size="small"
                    color="error"
                    aria-label="delete"
                >
                    <DeleteIcon />
                </IconButton>
            )}

            <FileUploadButton fileUpload={fileUpload} />
        </div>
    );
}

function ActNRP() {
    const dispatch = useAppDispatch();
    const issue = useSelector(issueSelector);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [actNrpId, setActNrpId] = useState<string | null>(issue?.diagnostic?.act_nrp_file?.id || null);

    function handleEditMode(value: boolean) {
        setEditMode(value);
    }

    async function handleApply() {
        if (!issue) {
            return;
        }
        if (actNrpId === issue.diagnostic?.act_nrp_file?.id) {
            return;
        }

        try {
            await dispatch(changeIssueActNRPThunk(issue.id, actNrpId));
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            handleEditMode(false);
        }
    }

    return (
        issue && (
            <div className="dv-issue-act-nrp">
                {!editMode ? (
                    <ActNRPPreview actNRP={issue.diagnostic?.act_nrp_file} />
                ) : (
                    <ActNRPEdit
                        handleChanged={setActNrpId}
                        actNRP={issue.diagnostic?.act_nrp_file}
                    />
                )}
                <div className="act-nrp-files">
                    {!editMode ? (
                        <IconButton
                            onClick={() => {
                                handleEditMode(true);
                            }}
                            disabled={issue.status !== EIssueStatus.ActNRP}
                            className="edit-iss-prop-icon"
                        >
                            <EditSharpIcon />
                        </IconButton>
                    ) : (
                        <>
                            <IconButton
                                title="Сохранить"
                                onClick={handleApply}
                                className="save-remove-buttons"
                                sx={{ marginRight: '30px' }}
                            >
                                <CheckIcon />
                            </IconButton>
                            <IconButton
                                title="Отмена"
                                onClick={() => {
                                    handleEditMode(false);
                                }}
                                className="save-remove-buttons"
                            >
                                <ClearIcon />
                            </IconButton>
                        </>
                    )}
                </div>
            </div>
        )
    );
}

export default memo(ActNRP);
