import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import EditIssueClient from './EditIssueClient';
import { EditIssueClientTags } from './EditIssueClientTags';

import Accordion from '../../../../components/Accordion';
import UserSmallData from '../../../../components/UserSmallData';
import { issueSelector } from '../../../../store/selectors/issuesSelectors';

import './IssueClient.scss';

function IssueClient() {
    const issue = useSelector(issueSelector);

    const { client } = issue || {};

    client?.id;

    return issue ? (
        <Accordion
            title="Клиент"
            sx={{ mt: 3 }}
        >
            <div className="iss-cl-list">
                <div className="iss-cl-item">
                    <div>Клиент</div>
                    <div>
                        {!!client && !client.is_removed ? (
                            <>
                                <UserSmallData user={client} />
                                <EditIssueClient />
                            </>
                        ) : (
                            <span style={{ color: '#8A92A6' }}>Пользователь удален</span>
                        )}
                    </div>
                </div>
                {!!client && (
                    <div className="iss-cl-item">
                        <div>Тэги</div>
                        <div style={{ maxWidth: 'calc(100% - 150px)', width: '100%' }}>
                            <EditIssueClientTags id={client.id} />
                        </div>
                    </div>
                )}

                {!!client && !client.is_removed && (
                    <>
                        <div className="iss-cl-item">
                            <div>Основной телефон</div>
                            <div>+{issue.client?.phone_number}</div>
                        </div>
                        {!!client?.phones &&
                            client?.phones.map(({ phone_number }, index) => {
                                const name = index.toString();
                                return (
                                    <div
                                        key={name}
                                        className="iss-cl-item"
                                    >
                                        <div>Добавочный телефон</div>
                                        <div>+{phone_number}</div>
                                    </div>
                                );
                            })}
                    </>
                )}
            </div>
        </Accordion>
    ) : null;
}

export default memo(IssueClient);
