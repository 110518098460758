import { servicesApi } from 'api';
import { createSlice } from 'store/hooks';
import { RootState } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import { PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from 'interfaces/pagination';

import { TFilters, TService, TServiceType, TState } from './types';

export const initialState: TState = {
    services: [],
    serviceById: null,

    chooseForDelete: null,

    service_types: [],

    categories: [],

    filters: {
        search: '',
        service_type_id__in: [],
        category_id__in: [],
        is_root: true,
    },

    pagination: {
        page: 1,
        size: 50,
        total: 0,
    },

    loading: false,
};

const slice = createSlice({
    name: 'services',
    initialState,
    reducers: (create) => ({
        fetchServices: create.asyncThunk<void, void>(
            async (_, { dispatch, getState }) => {
                const { filters, pagination } = (getState() as RootState).services;

                // const is_root =
                //     filters.service_type_id__in.length || filters.category_id__in.length || filters.search
                //         ? undefined
                //         : true;

                const params = getFilledParams({
                    size: pagination.size,
                    page: pagination.page,
                    ...filters,
                    ordering: '-created_at',
                    // is_root,
                });

                const { data } = await servicesApi.getServices(params);

                dispatch(setPagination({ ...pagination, total: data.total }));

                dispatch(setServices(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setServices: create.reducer((state, { payload }: PayloadAction<TService[]>) => {
            state.services = payload;
        }),

        fetchServiceTypes: create.asyncThunk<void, void>(
            async (_, { dispatch }) => {
                const { data } = await servicesApi.getServiceTypes();

                dispatch(setServiceTypes(data));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setServiceTypes: create.reducer((state, { payload }: PayloadAction<TServiceType[]>) => {
            state.service_types = payload;
        }),

        fetchCategories: create.asyncThunk(
            async (params, { dispatch }) => {
                const { data } = await servicesApi.getCategories(params);

                dispatch(setCategories(data));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),
        setCategories: create.reducer((state, { payload }: PayloadAction<any>) => {
            state.categories = payload;
        }),

        fetchServiceById: create.asyncThunk(async (id, { dispatch }) => {
            const { data } = await servicesApi.getServiceById(id);

            dispatch(setServiceById(data));
        }),

        setServiceById: create.reducer<null>((state, { payload }: PayloadAction<any>) => {
            state.serviceById = payload;
        }),

        chooseDelete: create.reducer((state, { payload }: PayloadAction<string | null>) => {
            state.chooseForDelete = payload;
        }),

        setFilters: create.reducer((state, { payload }: PayloadAction<Partial<TFilters>>) => {
            state.filters = { ...state.filters, ...(payload as any) };
        }),
        setPagination: create.reducer((state, { payload }: PayloadAction<Partial<IPagination>>) => {
            state.pagination = { ...state.pagination, ...payload };
        }),
    }),
});

export const {
    chooseDelete,
    setServices,
    setServiceTypes,
    setCategories,
    setFilters,
    setServiceById,
    setPagination,
    fetchServices,
    fetchServiceTypes,
    fetchCategories,
    fetchServiceById,
} = slice.actions;

export default slice.reducer;
