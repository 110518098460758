import Accordion from 'components/Accordion';
import { issueSelector } from 'store/selectors/issuesSelectors';
import { dateFormatter } from 'utils/helpers/dateFormatter';
import priceFormatter from 'utils/helpers/priceFormatter';

import { Box, Chip, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

import ActNRP from './ActNrp';
import Description from './Description';
import HelpType from './HelpType';
import Note from './Note';
import { PaymentMethod } from './PaymentMethod';
import PhotoFiles from './PhotoFiles';
import Sale from './Sale';
import Sources from './Sources';
import Warranty from './Warranty';

import './DeviceProblem.scss';

function DeviceProblem() {
    const issue = useSelector(issueSelector);
    return issue ? (
        <Accordion
            title={<Typography>Техника/Проблема</Typography>}
            sx={{ mt: 3 }}
        >
            <div className="dv-pr-list">
                <div className="dv-pr-item">
                    <div>Дата создания</div>
                    <div>{dateFormatter(issue.created_at)}</div>
                </div>
                <div className="dv-pr-item">
                    <div>Источник</div>
                    <Sources />
                </div>
                <div className="dv-pr-item">
                    <div>Тип заявки</div>
                    <HelpType />
                </div>
                <Box
                    className="dv-pr-item"
                    sx={{ alignItems: 'center' }}
                >
                    <div>Гарантийный ремонт</div>
                    <Warranty />
                </Box>
                <div className="dv-pr-item">
                    <div>Цена</div>
                    <div>{priceFormatter(issue.price?.total_discount_amount)}</div>
                </div>
                <div className="dv-pr-item">
                    <div>Способ оплаты</div>
                    <PaymentMethod />
                </div>
                <div className="dv-pr-item">
                    <div>Техника</div>
                    <div>
                        {issue.sub_issues?.map(({ home_device }, index) => {
                            const name = index.toString();
                            return (
                                <Box
                                    key={name}
                                    display="flex"
                                    alignItems="center"
                                    flexWrap="wrap"
                                    gap={1}
                                >
                                    {[home_device.category?.name, home_device.brand?.name, home_device.model?.name]
                                        .filter((str) => !!str)
                                        .join(' ')}
                                    {!!home_device.promotions.length &&
                                        home_device.promotions.map((promotion) => (
                                            <Chip
                                                key={promotion.id}
                                                color="error"
                                                label={`Акция - ${Math.round(promotion.discount * 100)}%`}
                                            />
                                        ))}
                                </Box>
                            );
                        })}
                    </div>
                </div>
                <Box
                    className="dv-pr-item"
                    sx={{ alignItems: 'center' }}
                >
                    <div>Проблема</div>
                    <Description />
                </Box>
                <Box
                    className="dv-pr-item"
                    sx={{ alignItems: 'center' }}
                >
                    <div>Фото</div>
                    <PhotoFiles photoFiles={issue.photo_files} />
                </Box>

                <Box
                    className="dv-pr-item"
                    sx={{ alignItems: 'center' }}
                >
                    <div>Примечание к заявке</div>
                    <Note />
                </Box>
                <Box
                    className="dv-pr-item"
                    sx={{ alignItems: 'center' }}
                >
                    <div>Для продажи</div>
                    <Sale />
                </Box>
                <Box className="dv-pr-item">
                    <div>Акт НРП</div>
                    <ActNRP />
                </Box>
            </div>
        </Accordion>
    ) : null;
}

export default DeviceProblem;
