import { ERoutes } from 'enums/routes.enum';
import { TSuggestedPrice } from 'pages/accounting/deposits/store/types';

import { Table as MuiTable, styled, TableCell, TableBody, TableRow, TableHead } from '@mui/material';
import { FC } from 'react';
import { NumericFormat } from 'react-number-format';
import { Link } from 'react-router-dom';

type Props = {
    rows: TSuggestedPrice[];
    issueOid: number;
    issueCompanyName: string;
    issueId: string;
};

export const Table: FC<Props> = ({ rows, issueOid, issueCompanyName, issueId }) => {
    return (
        <MuiTable
            sx={{
                display: 'grid',
                gridTemplateColumns: '100px 150px 100px 120px 1fr 130px 100px 130px',
                gridAutoFlow: 'row',
                width: '100%',
            }}
        >
            <TableHead sx={{ display: 'contents' }}>
                <TableRow sx={{ display: 'contents' }}>
                    <HeadCell sx={{ paddingLeft: '2px' }}>ЗАЯВКА</HeadCell>
                    <HeadCell>ПАРТНЕР</HeadCell>
                    <HeadCell>ПЛАТЕЛЬЩИК</HeadCell>
                    <HeadCell align="center">ТИП УСЛУГИ</HeadCell>
                    <HeadCell align="center">НАИМЕНОВАНИЕ УСЛУГИ</HeadCell>
                    <HeadCell align="center">ЦЕНА</HeadCell>
                    <HeadCell align="center">ПРОЦЕНТ</HeadCell>
                    <HeadCell align="center">СУММА</HeadCell>
                </TableRow>
            </TableHead>
            <TableBody sx={{ display: 'contents' }}>
                {rows.map((row) => {
                    return (
                        <TableRow
                            key={row.id}
                            sx={{ display: 'contents' }}
                        >
                            <Cell sx={{ paddingLeft: '2px' }}>
                                <Link
                                    to={`/${ERoutes.Issues}/${issueId}`}
                                    target="_blank"
                                >
                                    {issueOid}
                                </Link>
                            </Cell>
                            <Cell>{issueCompanyName}</Cell>
                            <Cell>
                                {!row.price_recipient ? '-' : row.price_recipient === 'client' ? 'Клиент' : 'Партнер'}
                            </Cell>
                            <Cell
                                align="center"
                                sx={{ maxWidth: '200px' }}
                            >
                                {row.price.service.name}
                            </Cell>
                            <Cell align="center">{row.price.name}</Cell>
                            <Cell align="center">
                                <NumericFormat
                                    displayType="text"
                                    value={row.amount}
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                />
                            </Cell>
                            <Cell align="center">
                                {row.commission_type === 'fixation'
                                    ? '-'
                                    : `${Math.floor(row.commission_amount * 100)} %`}
                            </Cell>
                            <Cell align="center">
                                <NumericFormat
                                    displayType="text"
                                    value={
                                        row.commission_type === 'fixation'
                                            ? row.commission_amount
                                            : row.amount * row.commission_amount
                                    }
                                    suffix=" ₽"
                                    thousandSeparator=" "
                                    decimalScale={2}
                                />
                            </Cell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </MuiTable>
    );
};

const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
});

const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
});
