import { Option } from 'utils/helpers/selectMapper';

import CloseIcon from '@mui/icons-material/Close';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/DriveFileRenameOutlineOutlined';
import EditSharpIcon from '@mui/icons-material/EditSharp';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import Autocomplete from '../../../../../components/inputs/Autocomplete';
import { EIssueCreatedSource } from '../../../../../enums/issues.enum';
import { IOption } from '../../../../../interfaces';
import { IIssue } from '../../../../../interfaces/issues';
import { issueCreatedSourceMock } from '../../../../../mockData/issues';
import { putIssueThunk } from '../../../../../store/actions/issues';
import { getPartnersThunk, setPartnersAction } from '../../../../../store/actions/partners';
import { useAppDispatch } from '../../../../../store/hooks';
import { issueSelector } from '../../../../../store/selectors/issuesSelectors';
import { partnersOptionsSelector } from '../../../../../store/selectors/partnersSelectors';
import defaultErrorCallback from '../../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../../utils/helpers/getErrorString';

import './Sourses.scss';

interface IFormValues {
    created_source?: IIssue['created_source'];
    partner_id?: IIssue['partner_id'];
}

function Sources() {
    const dispatch = useAppDispatch();

    const issue = useSelector(issueSelector);
    const partnersOptions = useSelector(partnersOptionsSelector);

    const [isOpen, setIsOpen] = useState(false);
    const [formValues, setFormValues] = useState<IFormValues | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const defaultValues = useMemo<{ partner_id?: IOption<string>; created_source?: IOption<string> } | null>(() => {
        let defaultPartner = null;
        let defaultCreatedSource = null;
        if (issue?.partner) {
            defaultPartner = {
                value: issue.partner.id,
                label: issue.partner.name,
            };
        }
        if (issue?.created_source) {
            defaultCreatedSource = {
                value: issue.created_source,
                label: issueCreatedSourceMock[issue.created_source],
            };
        }
        return {
            partner_id: defaultPartner || undefined,
            created_source: defaultCreatedSource || undefined,
        };
    }, [issue?.created_source, issue?.partner?.id, partnersOptions]);

    useEffect(() => {
        async function handle() {
            if (isOpen) {
                try {
                    await dispatch(getPartnersThunk());
                } catch (err) {
                    defaultErrorCallback({ errorMessage: getErrorString({ err }) });
                }
            } else {
                dispatch(setPartnersAction(null));
            }
        }
        handle();
    }, [isOpen]);

    function changeCreatedSourceHandler(option: IOption | null) {
        const val = option?.value;
        const newFormValues: IFormValues = formValues ? { ...formValues } : {};
        if (val) {
            newFormValues.created_source = val as EIssueCreatedSource;
        } else {
            newFormValues.created_source = undefined;
        }
        setFormValues(newFormValues);
    }

    function changePartnerIdHandler(option: (Option & { partner_id: string }) | null) {
        const val = option?.partner_id;
        const newFormValues: IFormValues = formValues ? { ...formValues } : {};
        if (val) {
            newFormValues.partner_id = val as IIssue['partner_id'];
        } else {
            newFormValues.partner_id = undefined;
        }
        setFormValues(newFormValues);
    }

    function handleClose() {
        setIsOpen(false);
    }

    async function putSourcesHandler() {
        try {
            if (!formValues || !issue) return;
            setIsLoading(true);
            await dispatch(
                putIssueThunk({
                    issueId: issue.id,
                    newIssue: {
                        sources: {
                            created_source: defaultValues?.created_source?.value,
                            partner_id: defaultValues?.partner_id?.value,
                            ...formValues,
                        },
                    },
                }),
            );
            handleClose();
        } catch (err) {
            defaultErrorCallback({ errorMessage: getErrorString({ err }) });
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div className="val-prop-iss">
            {!!issue?.created_source && (
                <>
                    {issueCreatedSourceMock[issue?.created_source as EIssueCreatedSource]}
                    {!!issue?.partner && <span>/</span>}
                    {issue?.partner?.name}
                </>
            )}
            <IconButton
                onClick={() => {
                    setIsOpen(!isOpen);
                }}
                className="edit-iss-prop-icon"
            >
                <EditSharpIcon />
            </IconButton>
            <Dialog
                open={isOpen}
                maxWidth="sm"
                fullWidth
                scroll="body"
                disableEscapeKeyDown
                onClose={handleClose}
            >
                <DialogTitle>
                    <Box className="modal-description-title">
                        <div className="close-icon">
                            <CloseIcon onClick={handleClose} />
                        </div>
                        <DriveFileRenameOutlineOutlinedIcon className="title-icon" />{' '}
                        <div className="title-text">Изменить источник</div>
                    </Box>
                </DialogTitle>
                <DialogContent>
                    <Autocomplete
                        name="created_source"
                        onChange={changeCreatedSourceHandler}
                        label={'Источник'}
                        margin="normal"
                        options={Object.keys(issueCreatedSourceMock).map((key) => {
                            return {
                                value: key,
                                label: issueCreatedSourceMock[key as keyof typeof issueCreatedSourceMock],
                            };
                        })}
                        defaultValue={defaultValues?.created_source}
                        isOptionEqualToValue={() => true}
                    />

                    <Autocomplete
                        name="partner_id"
                        onChange={changePartnerIdHandler}
                        label={'Партнер'}
                        options={partnersOptions}
                        defaultValue={defaultValues?.partner_id as Option & { partner_id: string }}
                        isOptionEqualToValue={() => true}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Отменить</Button>
                    <LoadingButton
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={putSourcesHandler}
                        variant="contained"
                    >
                        Сохранить
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default memo(Sources);
