import { Box, Divider, Paper, styled, TextField, Typography } from '@mui/material';

export const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
}); // double

export const MuiDivider = styled(Divider)({
    color: 'rgba(0, 0, 0, 0.08)',
    marginBottom: '35px',
}); // double

export const ModalWrapper = styled(Paper)({
    marginTop: '20px',
    padding: '3px 18px 18px 18px',
    height: '100%',
    borderRadius: '8px',
    // overflow: 'hidden',
}); // double

export const Field = styled('div')({
    display: 'flex',
    alignItems: 'center',
}); // double

export const SubTitle = styled(Typography)({
    width: '210px',
    fontSize: '14px',
});

export const MuiTextField = styled(TextField)({
    width: '392px',
});

export const FieldsWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

export const TitleWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'end',
    gap: '20px',
}); // double
