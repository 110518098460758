export const Confirm = ({ isDisabled }: { isDisabled?: boolean }) => {
    return (
        <svg
            width="26"
            height="26"
            viewBox="0 0 26 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                width="26"
                height="26"
                rx="5"
                fill={isDisabled ? '#C0C0C0' : '#0040F1'}
            />
            <path
                d="M21.2046 8.68604L10.8383 19.0523L5.65526 13.8692"
                stroke="#F5F7FC"
                strokeWidth="2"
                strokeLinejoin="bevel"
            />
        </svg>
    );
};
