import LoadingButton from '@mui/lab/LoadingButton';
import { Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { ECreateIssuePartnerSteps } from '../../../../enums/createIssuePartnerForm.enum';
import { TErrors } from '../../../../interfaces';
import {
    createIssuePartnerThunk,
    setErrorsAction,
    setSetFormStepAction,
} from '../../../../store/actions/create/createIssuePartner';
import { setIssueAction } from '../../../../store/actions/issues';
import { useAppDispatch } from '../../../../store/hooks';
import {
    amountTotalSelector,
    errorsCreateIssuePartnerFormSelector,
    formValuesSelector,
    selectedPricesSelector,
} from '../../../../store/selectors/create/createIssuePartnerSelectors';
import defaultErrorCallback from '../../../../utils/helpers/defaultErrorCallback';
import { getErrorString } from '../../../../utils/helpers/getErrorString';
import { getNumWord } from '../../../../utils/helpers/getNumWord';
import { isDateFuture } from '../../../../utils/helpers/isDateFuture';
import priceFormatter from '../../../../utils/helpers/priceFormatter';
import { onlyNumberReg } from '../../../../utils/regex';
import './FooterForm.scss';

function FooterForm() {
    const dispatch = useAppDispatch();

    const selectedPrices = useSelector(selectedPricesSelector);
    const formValues = useSelector(formValuesSelector);
    const errors = useSelector(errorsCreateIssuePartnerFormSelector);
    const amountTotal = useSelector(amountTotalSelector);

    const [isBtnDisabled, setIsBtnDisabled] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const validator = useCallback(() => {
        const errs = {} as TErrors;

        if (!formValues?.address) {
            errs.address = { message: 'Обязательное поле' };
        }

        if (!formValues?.first_name) {
            errs.first_name = { message: 'Обязательное поле' };
        }

        const regx = onlyNumberReg;

        if (formValues?.floor && !regx.test(formValues?.floor)) {
            errs.floor = { message: 'Только цифры' };
        }
        if (formValues?.entrance && !regx.test(formValues?.entrance)) {
            errs.entrance = { message: 'Только цифры' };
        }
        if (formValues?.apartment && !regx.test(formValues?.apartment)) {
            errs.apartment = { message: 'Только цифры' };
        }
        if (formValues?.intercom && !regx.test(formValues?.intercom)) {
            errs.intercom = { message: 'Только цифры' };
        }

        const phone_number = formValues?.phone_number?.replaceAll(' ', '');
        if (!phone_number) {
            errs.phone_number = { message: 'Обязательное поле' };
        }
        if (phone_number) {
            if (phone_number?.length < 11) {
                errs.phone_number = { message: 'Не менее 11 символов' };
            }
        }

        if (!formValues?.date) {
            errs.date = { message: 'Обязательное поле' };
        }

        if (formValues?.date) {
            if (!isDateFuture(formValues.date)) {
                errs.date = { message: 'Не корректное значение' };
            }
        }

        if (!selectedPrices?.length) {
            errs.prices = { message: 'Обязательное поле' };
        }

        // if (selectedPrices) {
        //     if (selectedPrices.every(({ service }) => service.is_additional)) {
        //         errs.prices = { message: 'Обязательное поле' };
        //     }
        // }

        if (!formValues?.codes?.issue) {
            errs.issue = { message: 'Обязательное поле' };
        }

        if (!formValues?.codes?.code) {
            errs.code = { message: 'Обязательное поле' };
        }

        dispatch(setErrorsAction(errs));
        return !Object.values(errs).some((val) => val);
    }, [formValues, selectedPrices]);

    useEffect(() => {
        if (errors) {
            validator();
        }

        if (!formValues) {
            setIsBtnDisabled(true);
        } else {
            setIsBtnDisabled(false);
        }
    }, [formValues, selectedPrices]);

    async function submitFormHandler() {
        if (!formValues) return;

        if (validator() && selectedPrices) {
            try {
                setIsLoading(true);
                const newIssue = await dispatch(createIssuePartnerThunk({ formValues, selectedPrices }));
                dispatch(setIssueAction(newIssue));
                dispatch(setSetFormStepAction(ECreateIssuePartnerSteps.Success));
            } catch (err) {
                defaultErrorCallback({ errorMessage: getErrorString({ err }) });
            } finally {
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="partner-form-footer">
            <Typography
                variant="h5"
                mb={2}
            >
                Ваш заказ
            </Typography>
            <div className="footer-info">
                <div className="counter-text">
                    {selectedPrices?.length || 0}{' '}
                    {getNumWord(selectedPrices?.length || 0, ['услуга', 'услуги', 'услуг'])}
                </div>
                <div className="info-price">{priceFormatter(amountTotal)}</div>
            </div>
            <LoadingButton
                onClick={submitFormHandler}
                variant="contained"
                size="large"
                fullWidth
                sx={{
                    mt: '24px',
                    background: '#1B2040',
                    borderRadius: '16px',
                    p: '16px 24px',
                }}
                loading={isLoading}
                disabled={isBtnDisabled || isLoading}
            >
                Отправить заявку
            </LoadingButton>
        </div>
    );
}

export default FooterForm;
