import { Box, Popover, styled } from '@mui/material';

export const Service = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '14px 14px 14px 10px',
    border: '1px solid #E9ECEF',
    borderRadius: '4px',
    position: 'relative',
    cursor: 'pointer',
    userSelect: 'none',
});

export const ServiceWrapper = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});

export const ChildWrapper = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
    display: isOpen ? 'block' : 'none',
    marginLeft: '32px',
    marginTop: '14px',
}));

export const ExpandIconWrapper = styled('div')(({ isOpen }: { isOpen: boolean }) => ({
    transition: 'all 0.3s',
    rotate: isOpen ? '0deg' : '-90deg',
    color: '#ADB5BD',
    cursor: 'pointer',
    padding: '0 2px',
}));

export const IconWrapper = styled('div')({
    position: 'absolute',
    right: '0',
    bottom: '0',
    transform: 'translate(50%, 50%)',
});

export const CategoryItem = styled(Box)({
    fontSize: '12px',
    backgroundColor: '#EAEDFB',
    color: '#232D42',
    borderRadius: '4px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px',
});

export const CategoriesBox = styled(Box)(
    ({ isActive, isShouldOpening }: { isActive?: boolean; isShouldOpening: boolean }) => ({
        fontSize: '12px',
        backgroundColor: isShouldOpening && isActive ? '#232D42' : '#EAEDFB',
        color: isShouldOpening && isActive ? 'white' : '#232D42',
        borderRadius: '4px',
        padding: '8px',
        display: 'flex',
        alignItems: 'center',
        gap: '4px',
        cursor: isShouldOpening ? 'pointer' : 'default',
    }),
);

export const CategoriesContainer = styled(Box)({
    display: 'flex',
    gap: '8px',
});

export const Badge = styled('div')(({ isActive }: { isActive: boolean }) => ({
    height: '22px',
    width: '22px',
    backgroundColor: isActive ? 'white' : '#232D42',
    color: isActive ? '#232D42' : 'white',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: '10px',
}));

export const CloseIconWrapper = styled('div')({
    position: 'absolute',
    right: '7px',
    top: '3px',
    cursor: 'pointer',
});

export const CategoriesWrapper = styled('div')({
    width: '200px',
    marginTop: '14px',
    display: 'flex',
    gap: '8px',
    flexWrap: 'wrap',
});

export const MuiPopover = styled(Popover)({
    '.MuiPaper-root': {
        borderRadius: '8px',
        padding: '18px',
    },
});
