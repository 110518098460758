import { servicesPriceListsApi } from 'api';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { useAppDispatch } from 'store/hooks';
import { servicesSelector } from 'store/selectors/servicesSelector';

import { Button, Dialog, DialogContent, DialogTitle, TextField, Typography } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';

import { fetchServicesPriceLists } from '../../store';
import { ButtonsWrapper, CloseIconWrapper } from '../styles';

type Props = {
    isOpen: boolean;
    setOpen: (value: boolean) => void;
};

export const EditAmountModal: FC<Props> = ({ isOpen, setOpen }) => {
    const dispatch = useAppDispatch();

    const { serviceById } = useSelector(servicesSelector);

    const [amount, setAmount] = useState<string>();

    return (
        <Dialog
            open={isOpen}
            onClose={() => {
                setAmount('');
                setOpen(false);
            }}
            sx={{
                '.MuiDialog-paper': {
                    width: '450px',
                    borderRadius: '8px',
                },
            }}
        >
            <DialogTitle>
                <CloseIconWrapper
                    onClick={() => {
                        setAmount('');
                        setOpen(false);
                    }}
                >
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography variant="h5">Стоимость комплекса услуг для всех прайс-листов</Typography>
            </DialogTitle>
            <DialogContent>
                <TextField
                    type="number"
                    label="Стоимость"
                    sx={{ marginTop: '14px', width: '400px' }}
                    onChange={(e) => {
                        setAmount(e.target.value);
                    }}
                />
                <ButtonsWrapper>
                    <Button
                        disabled={!amount}
                        variant="contained"
                        onClick={() => {
                            servicesPriceListsApi
                                .editAmountBulkUpdate(serviceById?.id as string, {
                                    value: amount as string,
                                })
                                .then(() => {
                                    enqueueSnackbar('Изменения сохранены', { variant: 'success' });
                                    dispatch(fetchServicesPriceLists());
                                    setAmount('');
                                })
                                .catch((e) => {
                                    enqueueSnackbar(e.response.data.error?.message || e.message, {
                                        variant: 'error',
                                    });
                                });
                        }}
                    >
                        Подтвердить
                    </Button>
                    <Button
                        onClick={() => {
                            setAmount('');
                            setOpen(false);
                        }}
                        variant="outlined"
                    >
                        Отмена
                    </Button>
                </ButtonsWrapper>
            </DialogContent>
        </Dialog>
    );
};
