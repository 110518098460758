import { companiesApi, priceListsApi, servicesApi } from 'api';
import { fetchPriceLists } from 'pages/price-lists/store';
import { priceListsSelector } from 'pages/price-lists/store/selectors';
import { TPriceList } from 'pages/price-lists/store/types';
import { CloseIcon } from 'static/Icons/CloseIcon';
import { DeleteBasket } from 'static/Icons/DeleteBasket';
import { useAppDispatch } from 'store/hooks';
import { selectMapper, Option, regionsMapper } from 'utils/helpers/selectMapper';

import AddIcon from '@mui/icons-material/Add';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { LoadingButton } from '@mui/lab';
import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    TextField,
    Typography,
} from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useDebouncedCallback } from 'use-lodash-debounce';

import { ButtonsContainer, CloseIconWrapper, CopyItemWrapper, DeleteIconWrapper, Field, SubTitle } from './styles';

type Props = {
    setOpen: (isOpen: boolean) => void;
};

export const CopyModal: FC<Props> = ({ setOpen }) => {
    const dispatch = useAppDispatch();

    const { priceList } = useSelector(priceListsSelector);

    const priceListTemplate = {
        id: priceList?.id,
        company_id: priceList?.company_id,
        company_name: priceList?.company.name,
        region_id: priceList?.region_id,
        region_name: priceList?.region.title,
        system_name: priceList?.system_name,
        name: '',
        is_replace: false,
        service_type: priceList?.service_type,
    };

    const [priceListForCopy, setPriceListsForCopy] = useState([priceListTemplate]);

    const [companies, setCompanies] = useState<Option[]>([]);
    const [serviceTypes, setServiceTypes] = useState<Option[]>([]);
    const [regions, setRegions] = useState<Option[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const fetchAutocompleteData = async () => {
        const [companiesRes, serviceTypesRes, regionsRes] = await Promise.all([
            companiesApi.getCompanies(),
            servicesApi.getServiceTypes(),
            priceListsApi.getRegionality({}),
        ]);

        setCompanies(companiesRes.data.map(selectMapper));
        setServiceTypes(serviceTypesRes.data.map(selectMapper));
        setRegions(regionsRes.data.items.map(regionsMapper));
    };

    useEffect(() => {
        fetchAutocompleteData();
    }, []);

    const handleChange = ({ id, key, value }: { id: string; key: keyof TPriceList | 'is_replace'; value: any }) => {
        console.log(value);

        setPriceListsForCopy((prev) =>
            prev.map((item) => {
                if (id === item.id) {
                    const newItem = {
                        ...item,
                        [key]: value,
                    };

                    if (key === 'region_id') {
                        const findedRegion = regions.find((region) => region.value === newItem.region_id);
                        newItem.region_name = findedRegion?.label ?? '';

                        newItem.system_name = `${newItem.company_name}. ${newItem.service_type?.name}. ${
                            newItem.region_name ? newItem.region_name : ''
                        }`.trim();
                    }

                    if (key === 'company_id') {
                        const findedCompany = companies.find((company) => company.value === newItem.company_id);
                        newItem.company_name = findedCompany?.label ?? '';

                        newItem.system_name = `${newItem.company_name ? newItem.company_name + '.' : ''} ${
                            newItem.service_type?.name
                        }. ${newItem.region_name}`.trim();
                    }

                    return newItem;
                }

                return item;
            }),
        );
    };

    const handleSearch = async (value: string) => {
        const { data } = await priceListsApi.getRegionality({ search: value });
        setRegions(data.items.map(regionsMapper));
    };

    const handleSearchDebounced = useDebouncedCallback(handleSearch, 700);

    const handleConfirm = () => {
        const items = priceListForCopy.map(({ company_id, region_id, system_name, name, is_replace }) => ({
            company_id,
            region_id,
            name,
            system_name,
            is_replace,
        }));

        setIsLoading(true);

        priceListsApi
            .copyPriceList({ items, original_id: priceListTemplate?.id })
            .then(() => {
                dispatch(fetchPriceLists());
                enqueueSnackbar('Прайс-лист успешно скопирован', { variant: 'success' });
                setOpen(false);
            })
            .catch((e) => {
                enqueueSnackbar(e.response.data.error?.message || e.message, { variant: 'error' });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    return (
        <Dialog
            open
            onClose={() => setOpen(false)}
            sx={{ '.MuiPaper-root': { maxWidth: '730px' } }}
        >
            <DialogTitle>
                <CloseIconWrapper onClick={() => setOpen(false)}>
                    <CloseIcon />
                </CloseIconWrapper>
                <Typography
                    fontWeight={700}
                    sx={{ fontSize: '24px' }}
                >
                    Копировать прайс-лист
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
                    {priceListForCopy.map((priceList, index) => (
                        <CopyItemWrapper key={priceList.id}>
                            <Field>
                                <SubTitle>Партнер</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    isOptionEqualToValue={(option, { value }) => option.value === value}
                                    options={companies}
                                    value={{
                                        label: priceList?.company_name,
                                        value: priceList?.company_id,
                                    }}
                                    defaultValue={{
                                        label: priceList?.company_name,
                                        value: priceList?.company_id,
                                    }}
                                    onChange={(_, data) =>
                                        handleChange({ id: priceList.id!, key: 'company_id', value: data?.value })
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Тип услуги</SubTitle>
                                <Autocomplete
                                    disabled
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    defaultValue={{
                                        label: priceList?.service_type?.name,
                                        value: priceList?.service_type?.id,
                                    }}
                                    isOptionEqualToValue={(option, { value }) => option.value === value}
                                    options={serviceTypes}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Регион</SubTitle>
                                <Autocomplete
                                    sx={{ width: '392px' }}
                                    size="small"
                                    popupIcon={<ExpandMoreIcon />}
                                    isOptionEqualToValue={(option, { value }) => option.value === value}
                                    options={regions}
                                    defaultValue={{
                                        label: priceList?.region_name,
                                        value: priceList?.region_id,
                                    }}
                                    onChangeCapture={({ target }) => {
                                        const { value } = target as EventTarget & HTMLInputElement;

                                        handleSearchDebounced(value);
                                    }}
                                    onChange={(_, data) =>
                                        handleChange({ id: priceList.id!, key: 'region_id', value: data?.value })
                                    }
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            placeholder="Выбрать"
                                        />
                                    )}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Системное наименование</SubTitle>
                                <TextField
                                    size="small"
                                    onChange={(e) =>
                                        handleChange({ id: priceList.id!, key: 'system_name', value: e.target.value })
                                    }
                                    value={priceList?.system_name}
                                    sx={{ width: '392px' }}
                                />
                            </Field>
                            <Field>
                                <SubTitle>Наименование</SubTitle>
                                <TextField
                                    size="small"
                                    sx={{ width: '392px' }}
                                    onChange={(e) =>
                                        handleChange({ id: priceList.id!, key: 'name', value: e.target.value })
                                    }
                                />
                            </Field>
                            <Field>
                                <SubTitle />
                                <FormControlLabel
                                    onChange={(_, value) =>
                                        handleChange({ id: priceList.id!, key: 'is_replace', value })
                                    }
                                    control={<Checkbox />}
                                    label="Заменить прайс-лист"
                                />
                            </Field>
                            <DialogActions sx={{ position: 'absolute', bottom: 0, right: 0, paddingRight: '11px' }}>
                                <DeleteIconWrapper
                                    onClick={() => {
                                        setPriceListsForCopy((prev) => prev.filter((_, idx) => idx !== index));
                                    }}
                                >
                                    <DeleteBasket />
                                </DeleteIconWrapper>
                            </DialogActions>
                        </CopyItemWrapper>
                    ))}
                </Box>

                <Button
                    sx={{ color: '#0040F1' }}
                    startIcon={<AddIcon />}
                    onClick={() =>
                        setPriceListsForCopy((prev) => [
                            ...prev,
                            { ...priceListTemplate, id: `${priceListTemplate!.id}-${prev.length}` },
                        ])
                    }
                >
                    Добавить копию
                </Button>
                <ButtonsContainer>
                    <LoadingButton
                        variant="contained"
                        onClick={handleConfirm}
                        loading={isLoading}
                        disabled={isLoading}
                    >
                        Подтвердить
                    </LoadingButton>
                    <Button
                        variant="outlined"
                        onClick={() => setOpen(false)}
                    >
                        Отмена
                    </Button>
                </ButtonsContainer>
            </DialogContent>
        </Dialog>
    );
};
