import { fetchPriceLists, setPagination } from 'pages/price-lists/store';
import { useAppDispatch } from 'store/hooks';

import { Table as MuiTable } from '@mui/material';
import { ChangeEvent, FC } from 'react';

import { Body } from './body';
import { Head } from './head';
import { Pagination, TableWrapper } from './styles';

type Props = {
    rows: any[];
    pagination: any;
};

export const Table: FC<Props> = ({ rows, pagination }) => {
    const dispatch = useAppDispatch();

    const onPageChange = (_: any, page: number) => {
        dispatch(setPagination({ page: page + 1 }));
        dispatch(fetchPriceLists());
    };

    const onRowsPerPageChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        dispatch(setPagination({ size: +e.target.value }));
        dispatch(fetchPriceLists());
    };

    return (
        <TableWrapper>
            <MuiTable stickyHeader>
                <Head />
                <Body rows={rows} />
            </MuiTable>
            <Pagination
                rowsPerPageOptions={[5, 25, 50]}
                count={pagination.total}
                rowsPerPage={pagination.size}
                page={pagination.page - 1}
                onPageChange={onPageChange}
                onRowsPerPageChange={onRowsPerPageChange}
            />
        </TableWrapper>
    );
};
