import { servicesPriceListsApi } from 'api';
import { Edit } from 'static/Icons/Edit';
import { EllipseGreen } from 'static/Icons/EllipseGreen';

import { Table as MuiTable, TableBody, TableHead, TableRow, TextField } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { FC, useState } from 'react';
import { NumericFormat } from 'react-number-format';

import { EditAmountModal } from './modals/edit-amount';
import { EditCommissionModal } from './modals/edit-commission';
import { CellInputProps, EditIconWrapper } from './styles';

import { Cell, HeadCell } from '../../create-service/styles';

export type TPriceList = {
    amount: number | null;
    availability_type: any | null;
    categories: any | null;
    category_ids: any[];
    commission: any;
    created_at: string;
    description: string;
    external_id: any | null;
    id: string;
    include_descriptions: any[];
    is_active: boolean;
    is_active_integration: boolean;
    is_removed: boolean;
    name: string;
    price_list: any;
    service_type: any;
    services: any[];
    updated_at: string;
};

type Props = {
    rows: TPriceList[];
};

export const Table: FC<Props> = ({ rows: rowsProps }) => {
    const [isOpenAmount, setOpenAmount] = useState<boolean>(false);
    const [isOpenCommission, setOpenCommission] = useState<boolean>(false);

    const [rows, setRows] = useState(rowsProps);

    return (
        <>
            <MuiTable
                sx={{
                    marginTop: '10px',
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 121px 121px',
                    gridAutoFlow: 'row',
                }}
            >
                <TableHead sx={{ display: 'contents' }}>
                    <HeadCell>ПРАЙС-ЛИСТ</HeadCell>
                    <HeadCell>КОМПЛЕКС УСЛУГ</HeadCell>
                    <HeadCell sx={{ textAlign: 'center' }}>
                        ЦЕНА
                        <EditIconWrapper onClick={() => setOpenAmount(true)}>
                            <Edit />
                        </EditIconWrapper>
                    </HeadCell>
                    <HeadCell sx={{ textAlign: 'center' }}>
                        КОМИССИЯ
                        <EditIconWrapper onClick={() => setOpenCommission(true)}>
                            <Edit />
                        </EditIconWrapper>
                    </HeadCell>
                </TableHead>
                <TableBody sx={{ display: 'contents' }}>
                    {rows?.map((row) => {
                        return (
                            <TableRow
                                key={row.id}
                                sx={{ display: 'contents' }}
                            >
                                <Cell sx={{ display: 'flex', alignItems: 'center' }}>
                                    <EllipseGreen status={row.price_list.is_active} />
                                    <span style={{ marginLeft: '5px' }}>{row.price_list.system_name}</span>
                                </Cell>
                                <Cell sx={{ display: 'flex', alignItems: 'center' }}>
                                    <EllipseGreen status={row.is_active} />
                                    <span style={{ marginLeft: '5px' }}>{row.name}</span>
                                </Cell>
                                <Cell>
                                    <NumericFormat
                                        inputProps={CellInputProps}
                                        suffix=" ₽"
                                        customInput={TextField}
                                        value={row.amount}
                                        onBlur={(e) => {
                                            const newValue = e.target.value.slice(0, -2)
                                                ? +e.target.value.slice(0, -2)
                                                : null;

                                            if (newValue === row?.amount) {
                                                return;
                                            }

                                            setRows((prev) =>
                                                prev.map((item) => {
                                                    if (item.id === row.id) {
                                                        return {
                                                            ...item,
                                                            amount: newValue,
                                                        };
                                                    }

                                                    return item;
                                                }),
                                            );

                                            servicesPriceListsApi
                                                .editServicesComplex(row.id, {
                                                    amount: newValue,
                                                })
                                                .then(() => {
                                                    enqueueSnackbar('Изменено', { variant: 'success' });
                                                })
                                                .catch((e) => {
                                                    enqueueSnackbar(
                                                        e.response.data.error?.message ||
                                                            (typeof e.response?.data !== 'string'
                                                                ? e.message
                                                                : e.response?.data),
                                                        {
                                                            variant: 'error',
                                                        },
                                                    );
                                                });
                                        }}
                                    />
                                </Cell>
                                <Cell>
                                    <NumericFormat
                                        inputProps={CellInputProps}
                                        suffix=" ₽"
                                        customInput={TextField}
                                        value={row.commission?.commission_value}
                                        onBlur={(e) => {
                                            const newValue = e.target.value.slice(0, -2)
                                                ? +e.target.value.slice(0, -2)
                                                : null;

                                            if (
                                                newValue ===
                                                (typeof row?.commission?.commission_value === 'number'
                                                    ? row.commission.commission_value
                                                    : null)
                                            ) {
                                                return;
                                            }

                                            setRows((prev) =>
                                                prev.map((item) => {
                                                    if (item.id === row.id) {
                                                        return {
                                                            ...item,
                                                            commission: {
                                                                ...item.commission,
                                                                commission_value: newValue,
                                                            },
                                                        };
                                                    }

                                                    return item;
                                                }),
                                            );

                                            servicesPriceListsApi
                                                .editServicesComplex(row.id, {
                                                    commission: {
                                                        value: newValue,
                                                    },
                                                })
                                                .then(() => {
                                                    enqueueSnackbar('Изменено', { variant: 'success' });
                                                })
                                                .catch((e) => {
                                                    enqueueSnackbar(
                                                        e.response.data.error?.message ||
                                                            (typeof e.response?.data !== 'string'
                                                                ? e.message
                                                                : e.response?.data),
                                                        {
                                                            variant: 'error',
                                                        },
                                                    );
                                                });
                                        }}
                                    />
                                </Cell>
                            </TableRow>
                        );
                    })}

                    <EditAmountModal
                        isOpen={isOpenAmount}
                        setOpen={setOpenAmount}
                    />
                    <EditCommissionModal
                        isOpen={isOpenCommission}
                        setOpen={setOpenCommission}
                    />
                </TableBody>
            </MuiTable>
        </>
    );
};
