import { Box, Checkbox, InputBaseComponentProps, styled, TextField, Typography } from '@mui/material';

export const CheckBoxContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
});

export const CheckBoxItem = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '7px',
});

export const SubTitle = styled(Typography)({
    width: '215px',
    fontSize: '14px',
});

export const SmallWrapper = styled(Box)({
    display: 'flex',
    gap: '8px',
});

export const CheckBox = styled(Checkbox)({
    padding: 0,
});

export const ServiceName = styled(Typography)({
    fontSize: '14px',
});

export const ButtonsWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginTop: '35px',
});

export const CheckLabel = styled('label')({
    cursor: 'pointer',
});

export const CellInputProps: InputBaseComponentProps = {
    style: {
        textAlign: 'center',
        padding: '8px',
        width: '100px',
        fontWeight: '400',
        fontSize: '14px',
    },
};

export const MuiTextField = styled(TextField)({
    width: '392px',
});

export const SelectedContainer = styled('div')((loading: { loading: boolean }) => ({
    position: 'relative',
    marginTop: '20px',
    height: loading ? '250px' : '0px',
    maxHeight: '250px',
    display: 'inline-flex',
    minWidth: '470px',
    flexDirection: 'column',
    gap: '8px',
    overflow: 'auto',
    padding: '5px',
}));

export const SelectItem = styled('div')({
    border: '2px solid #E9ECEF',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
});
