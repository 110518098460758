import { Autocomplete, Box, Button, Divider, styled, Tab, TableCell, Tabs } from '@mui/material';

export const Header = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    // position: 'fixed',
    // width: '955px',
    // background: 'white',
    // zIndex: '1000',
}); // double

export const MuiTab = styled(Tab)({
    textTransform: 'none',
    fontSize: '16px',
    padding: '0',
    marginRight: '40px',
    color: '#BCBFD1',
}); // double

export const MuiTabs = styled(Tabs)({
    '.css-1nlnn20-MuiButtonBase-root-MuiTab-root.Mui-selected': {
        color: 'black',
        fontWeight: 500,
    },
}); // double

export const CloseIconWrapper = styled('div')({
    cursor: 'pointer',
});

export const MuiDivider = styled(Divider)({
    color: 'rgba(0, 0, 0, 0.08)',
    marginBottom: '35px',
});

export const HeadCell = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
    padding: '16px 0',
});

export const HeadCellCenter = styled(TableCell)({
    color: '#9E9E9E',
    fontWeight: '600',
    fontSize: '12px',
    padding: '16px 0',
    textAlign: 'center',
}); // double

export const Cell = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
    padding: '16px 0',
});

export const CellCenter = styled(TableCell)({
    fontWeight: '400',
    fontSize: '14px',
    padding: '16px 0',
    textAlign: 'center',
}); // double

export const ModalWrapper = styled(Box)({
    padding: '3px 18px 18px 18px',
    width: '992px',
}); // double

export const Field = styled('div')({
    display: 'flex',
    alignItems: 'center',
}); // double

export const MuiAutocomplete = styled(Autocomplete)({
    width: '555px',
});

export const Asterisk = styled('span')({
    color: '#F56767',
}); // double

export const FieldContainer = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '14px',
    marginTop: '35px',
});

export const PlusButton = styled(Button)({
    color: '#0040F1',
});
