import Autocomplete from 'components/inputs/Autocomplete';
import { ERoutes } from 'enums/routes.enum';

import { Select, TableCell, TableRow } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { IOption } from 'interfaces';
import { IImportGsisData, IImportGsisSimilarIssue } from 'interfaces/importGsis';
import { memo } from 'react';
import { Link as RLink } from 'react-router-dom';

interface IProps {
    idx: number;
    item: IImportGsisData;
    onChangeSelectedSimilarIssue: (idx: number, value: string | null) => void;
    onChangeDevice: (option: IOption | null, id: string) => void;
    options: IOption[];
    searchClient: (val: string) => void;
    onBlurSearch: () => void;
}

interface ISimilarIssuesCellProps {
    idx: number;
    issue_code: string;
    similar_issues: IImportGsisSimilarIssue[];
    onChangeSelectedSimilarIssue: (idx: number, value: string | null) => void;
    selectedSimilarIssue: string | null;
}

function SimilarIssuesTableCell({
    idx,
    similar_issues,
    issue_code,
    onChangeSelectedSimilarIssue,
    selectedSimilarIssue,
}: ISimilarIssuesCellProps) {
    return (
        <>
            <TableCell>
                {similar_issues.map((issue) => (
                    <div
                        key={issue.id}
                        className="import-table-similar-issues-link"
                    >
                        <RLink
                            to={`/${ERoutes.Issues}/${issue.id}`}
                            target="_blank"
                        >
                            {issue.oid}
                        </RLink>
                    </div>
                ))}
            </TableCell>
            <TableCell>
                <Select
                    labelId={`similar_issues-${issue_code}`}
                    className="import-table-similar-issues-select"
                    name="similar_issues"
                    variant="standard"
                    margin="none"
                    label="Выберите дубль"
                    onChange={(e) => {
                        onChangeSelectedSimilarIssue(idx, e.target.value);
                    }}
                    value={selectedSimilarIssue || 'new'}
                >
                    <MenuItem value="new">Новая заявка</MenuItem>
                    {similar_issues.map((issue) => (
                        <MenuItem
                            key={issue.id}
                            value={issue.id}
                        >
                            {issue.oid}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
        </>
    );
}

function NotSimilarIssuesTableCell() {
    return (
        <TableCell colSpan={2}>
            <div className="import-table-similar-issues-not-found">Дубли не найдены</div>
        </TableCell>
    );
}

function ImportTableRow({
    idx,
    item,
    onChangeSelectedSimilarIssue,
    onChangeDevice,
    options,
    searchClient,
    onBlurSearch,
}: IProps) {
    return (
        <TableRow>
            <TableCell>{item.issue.issue_code}</TableCell>
            <TableCell>
                {item.client.first_name} {item.client.last_name} {item.client.middle_name || ''}
            </TableCell>
            <TableCell>{item.client.phone_number}</TableCell>
            <TableCell>{item.client.address.address}</TableCell>
            <TableCell sx={{ minWidth: '220px' }}>
                {!item.isLocal ? (
                    item.issue.home_device.category.name
                ) : (
                    <Autocomplete
                        name="home_device_id"
                        margin="none"
                        inpPlaceholder={'Техника*'}
                        options={options}
                        onChange={(option) => onChangeDevice(option, item.client.id)}
                        onInpChange={searchClient}
                        onInpBlur={onBlurSearch}
                    />
                )}
            </TableCell>
            <TableCell>{item.issue.home_device.serial_number}</TableCell>
            <TableCell>{item.issue.home_device.purchase_date}</TableCell>
            <TableCell>
                {item?.issue?.price?.details?.[0]?.price?.name ? item?.issue?.price?.details?.[0]?.price?.name : '-'}
            </TableCell>
            <TableCell className="import-table-cell-description">
                <pre>{item.issue.description ? item.issue.description : '-'}</pre>
            </TableCell>
            {!item.issue.similar_issues || item.issue.similar_issues?.length === 0 ? (
                <NotSimilarIssuesTableCell />
            ) : (
                <SimilarIssuesTableCell
                    idx={idx}
                    selectedSimilarIssue={item.selected_similar_issue}
                    onChangeSelectedSimilarIssue={onChangeSelectedSimilarIssue}
                    issue_code={item.issue.issue_code}
                    similar_issues={item.issue.similar_issues}
                />
            )}
        </TableRow>
    );
}

export default memo(ImportTableRow, () => {
    return false;
    //return prevProps.item.selected_similar_issue === nextProps.item.selected_similar_issue
});
