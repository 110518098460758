import { servicesPriceListsApi } from 'api';
import { TPriceListService } from 'pages/price-lists/store/types';
import { createSlice } from 'store/hooks';
import { RootState } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import { PayloadAction } from '@reduxjs/toolkit';

import { TFilters } from './types';

export const initialState = {
    servicesPriceLists: new Array<TPriceListService>(),

    filters: {
        pk__in: [],
        price_list_id__in: [],
        company_id__in: [],
        region_id__in: [],
    },

    loading: false,
};

const slice = createSlice({
    name: 'servicesPriceLists',
    initialState,
    reducers: (create) => ({
        fetchServicesPriceLists: create.asyncThunk<void, void>(
            async (_, { dispatch, getState }) => {
                const { filters } = (getState() as RootState).servicesPriceLists;
                const { serviceById } = (getState() as RootState).services;

                const params = getFilledParams({
                    with_paginate: false,
                    services_id: serviceById?.id,
                    ordering: 'created_at',
                    ...filters,
                });

                const { data } = await servicesPriceListsApi.getServicesComplexWithOutPaginate(params);

                dispatch(setServicesPriceLists(data));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),
        setServicesPriceLists: create.reducer((state, { payload }: PayloadAction<TPriceListService[]>) => {
            state.servicesPriceLists = payload;
        }),

        setFilters: create.reducer((state, { payload }: PayloadAction<Partial<TFilters>>) => {
            state.filters = { ...state.filters, ...(payload as any) };
        }),
    }),
});

export const { fetchServicesPriceLists, setServicesPriceLists, setFilters } = slice.actions;

export default slice.reducer;
