export const ExpandIcon = () => {
    return (
        <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M6 4.58579L1.70711 0.292892C1.31658 -0.097632 0.683416 -0.0976321 0.292892 0.292892C-0.0976315 0.683417 -0.0976316 1.31658 0.292892 1.70711L5.29289 6.70711C5.68342 7.09763 6.31658 7.09763 6.70711 6.70711L11.7071 1.70711C12.0976 1.31658 12.0976 0.683418 11.7071 0.292893C11.3166 -0.0976312 10.6834 -0.0976312 10.2929 0.292893L6 4.58579Z"
                fill="#ADB5BD"
            />
        </svg>
    );
};
