import { Box, InputBaseComponentProps, Paper, styled, Typography } from '@mui/material';

export const TitleWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'end',
    gap: '20px',
}); // double

export const ModalWrapper = styled(Paper)({
    marginTop: '20px',
    padding: '18px',
    height: '100%',
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflow: 'auto',
}); // double

export const Field = styled('div')({
    display: 'flex',
    alignItems: 'center',
}); // double

export const SubTitle = styled(Typography)({
    width: '210px',
    fontSize: '14px',
}); // double

export const Asterisk = styled('span')({
    color: '#F56767',
}); // double

export const FieldsContainer = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
});

export const ButtonsContainer = styled('div')({
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
    marginTop: '30px',
});

export const Container = styled('div')({
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
}); // double

export const CellInputProps: InputBaseComponentProps = {
    style: {
        textAlign: 'center',
        padding: '8px',
        width: '100px',
        fontWeight: '400',
        fontSize: '14px',
    },
};
