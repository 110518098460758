import Loader from 'components/Loader';
import { ERoutes } from 'enums/routes.enum';
import Reminder from 'modules/Reminder';
import { chatsTotalUnseenCountIssuesSelector } from 'store/selectors/chatsSelectors';
import { issueSelector } from 'store/selectors/issuesSelectors';

import { Chat as ChatIcon } from '@mui/icons-material';
import { Box, Typography, Fab, Badge } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import Chat from './Chat';
import ClientReview from './ClientReview';
import DeviceProblem from './DeviceProblem';
import Diagnostic from './Diagnostic';
import Documents from './Documents';
import IssueAddress from './IssueAddress';
import IssueClient from './IssueClient';
import IssuePrices from './IssuePrices';
import IssueStatus from './IssueStatus';
import IssueWorker from './IssueWorker';
import NotesMaster from './NotesMaster';
import ObservesIssue from './ObservesIssue';
import PartsOrders from './PartsOrders';
import PhotosMaster from './PhotosMaster';
import { ReasonRefusal } from './ReasonRefusal';
import StatusHistory from './StatusHistory';

import DeleteIssueReminder from '../DeleteIssueReminder';
import EditIssueReminder from '../EditIssueReminder';

import './IssueDetail.scss';

function IssueDetail() {
    const navigate = useNavigate();

    const issue = useSelector(issueSelector);
    const countChats = useSelector(chatsTotalUnseenCountIssuesSelector);

    const [isChatOpen, setChatOpen] = useState(false);

    useEffect(() => {
        if (!issue) return;
        switch (issue.is_archived) {
            case true: {
                navigate(`/${ERoutes.Archive}/${issue.id}`);
                break;
            }
            default:
                break;
        }
    }, [issue?.is_archived]);

    return (
        <Paper className="issue-detail-wrapper">
            {issue ? (
                <>
                    <Box
                        display="flex"
                        justifyContent="space-between"
                    >
                        <Box
                            display="flex"
                            justifyContent="space-between"
                            alignItems="center"
                            gap={2}
                        >
                            <Typography variant="h6">№ {issue.oid}</Typography>
                            <ObservesIssue />
                            <Reminder />
                            <EditIssueReminder />
                            <DeleteIssueReminder />
                        </Box>
                        <Box
                            display="flex"
                            justifyContent="space-between"
                        >
                            <StatusHistory />
                            <IssueStatus />
                        </Box>
                    </Box>
                    {issue.status === 'canceled' && <ReasonRefusal issueStatus={issue.status} />}
                    <DeviceProblem />
                    <Diagnostic />
                    <IssueWorker />
                    <IssueClient />
                    <IssueAddress />
                    <PartsOrders />
                    <IssuePrices />
                    <Documents />
                    <PhotosMaster />
                    <NotesMaster />
                    {issue.status !== 'canceled' && <ClientReview />}
                </>
            ) : (
                <Loader />
            )}

            {isChatOpen && (
                <Chat
                    onClose={() => setChatOpen(false)}
                    issue={issue}
                />
            )}

            <Fab
                onClick={() => setChatOpen((p) => !p)}
                className="chat-btn"
            >
                <Badge
                    badgeContent={countChats}
                    color="error"
                >
                    <div className="badge-inner">
                        <ChatIcon className="icon" />
                    </div>
                </Badge>
            </Fab>
        </Paper>
    );
}

export default IssueDetail;
