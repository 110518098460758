import { issuesApi } from 'api';
import { createSlice } from 'store/hooks';
import { RootState } from 'store/store';
import { getFilledParams } from 'utils/helpers/getValidParams';

import type { PayloadAction } from '@reduxjs/toolkit';
import { IPagination } from 'interfaces/pagination';

import { TDeposit, TFilters, TState } from './types';

export const initialState: TState = {
    deposits: [],
    depositIds: [],
    depositId: '',

    loading: false,
    pagination: {
        page: 1,
        size: 50,
        total: 0,
    },
    filters: {
        issue_id__in: [],
        worker_id__in: [],
        issue_created_at__gte: '',
        issue_created_at__lte: '',
        solved_at__gte: '',
        solved_at__lte: '',
        help_type__in: [],
        partner_id__in: [],
    },
};

const slice = createSlice({
    name: 'deposits',
    initialState,
    reducers: (create) => ({
        setDeposits: create.reducer((state, { payload }: PayloadAction<TDeposit[]>) => {
            state.deposits = payload;
        }),

        setDepositIds: create.reducer((state, { payload }: PayloadAction<string[]>) => {
            state.depositIds = payload;
        }),

        setDepositId: create.reducer((state, { payload }: PayloadAction<string>) => {
            state.depositId = payload;
        }),

        fetchDeposits: create.asyncThunk<void, void>(
            async (_, { dispatch, getState }) => {
                const { pagination, filters } = (getState() as RootState).deposits;

                const params = getFilledParams({
                    size: pagination.size,
                    page: pagination.page,
                    ...filters,
                    status__in: 'suggested',
                });

                const { data } = await issuesApi.getDeposits(params);

                dispatch(setPagination({ ...pagination, total: data.total }));

                dispatch(setDeposits(data.items));
            },
            {
                pending: (state) => {
                    state.loading = true;
                },
                settled: (state) => {
                    state.loading = false;
                },
            },
        ),

        setFilters: create.reducer((state, { payload }: PayloadAction<Partial<TFilters>>) => {
            state.pagination = { ...initialState.pagination, size: state.pagination.size };
            state.filters = { ...state.filters, ...payload };
        }),

        setPagination: create.reducer((state, { payload }: PayloadAction<Partial<IPagination>>) => {
            state.pagination = { ...state.pagination, ...payload };
        }),
    }),
});

export const {
    setDeposits,
    setDepositIds,
    setDepositId,
    fetchDeposits,

    setFilters,
    setPagination,
} = slice.actions;

export default slice.reducer;
