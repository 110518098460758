import { Box, Drawer, Paper, styled, TablePagination } from '@mui/material';

export const MainPaper = styled(Paper)({
    marginTop: '16px',
    position: 'relative',
    height: '100%',
    overflow: 'auto',

    '> div': {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        padding: '18px',
        borderRadius: '8px 8px 0 0',
    },
});

export const MuiDrawer = styled(Drawer)({
    '.MuiDrawer-paper': {
        marginTop: '80px',
        height: 'calc(100vh - 80px)',
        borderRadius: '8px 0 0 8px',
    },

    '.css-1160xiw-MuiPaper-root-MuiDrawer-paper': {
        boxShadow: '-13px 0px 13px -10px rgba(0,0,0,0.25)',
    },

    '.css-i9fmh8-MuiBackdrop-root-MuiModal-backdrop': {
        background: 'none !important',
    },
});

export const Container = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
});

export const CloseIconWrapper = styled('div')({
    position: 'absolute',
    right: 10,
    top: 0,
    cursor: 'pointer',
});

export const ControlsWrapper = styled(Box)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginTop: '16px',
});

export const FiltersWrapper = styled(Box)({
    display: 'flex',
    gap: '20px',
    alignItems: 'center',
});

export const Pagination = styled(TablePagination)({
    display: 'block',
    backgroundColor: '#fff',
    position: 'sticky',
    bottom: 0,
});
